/* eslint-disable indent */
import {
  array,
  object,
  string,
  date,
  boolean,
  AnyObject,
  Maybe,
  TestContext,
} from 'yup';
import i18n from '@app/i18n/config';
import {
  LICENSE_REG_EXP,
  maxLength,
  onlyCyrillic,
  onlyLetters,
  onlyNumbers,
} from '@shared/constants';
import { i18nDefaultValues } from '../i18n';
import { calculateAge, checkDateBefore } from '@shared/utils';
import { ALL_ZEROS } from '../constants';
import { ValidationContext } from '../types';

const errorMessages = i18nDefaultValues.WhoIsDriver.errors;
const notFilled = i18n.t(
  'SMART:WhoIsDriver.errors.notFilled',
  errorMessages.notFilled
);
const useCyrillic = i18n.t(
  'SMART:WhoIsDriver.errors.useCyrillic',
  errorMessages.useCyrillic
);
const nameStartWithLetter = i18n.t(
  'SMART:WhoIsDriver.errors.nameStartWithLetter',
  errorMessages.nameStartWithLetter
);
const lastnameStartWithLetter = i18n.t(
  'SMART:WhoIsDriver.errors.lastnameStartWithLetter',
  errorMessages.lastnameStartWithLetter
);
const middleNameStartWithLetter = i18n.t(
  'SMART:WhoIsDriver.errors.middleNameStartWithLetter',
  errorMessages.middleNameStartWithLetter
);
const useNumbersMessage = i18n.t(
  'SMART:WhoIsDriver.errors.useNumbers',
  errorMessages.useNumbers
);
const maxLengthMessage = i18n.t(
  'SMART:WhoIsDriver.errors.maxLength',
  errorMessages.maxLength
);
const maxAgeOfDriver = i18n.t(
  'SMART:WhoIsDriver.errors.maxAgeOfDriver',
  errorMessages.maxAgeOfDriver
);
const incorrectLicense = i18n.t(
  'SMART:WhoIsDriver.errors.incorrectLicense',
  errorMessages.incorrectLicense
);
const incorrectYearOfStart = i18n.t(
  'SMART:WhoIsDriver.errors.incorrectYearOfStart',
  errorMessages.incorrectYearOfStart
);

const validateMinAge = (
  value: Maybe<Date>,
  context: TestContext<AnyObject>
) => {
  const { path, createError, options } = context;
  const { minAgeOfDriver } = options.context as ValidationContext;

  const birthday = value ? new Date(value) : new Date();
  const isValid = minAgeOfDriver <= calculateAge(birthday);

  if (isValid) {
    return true;
  }

  const minAgeOfDriverError = i18n.t(
    'SMART:WhoIsDriver.errors.minAgeOfDriver',
    {
      minAgeOfDriver: minAgeOfDriver,
      defaultValue: errorMessages.minAgeOfDriver,
    }
  );

  return createError({
    path,
    message: minAgeOfDriverError,
  });
};

function validateMinLicenseDate(
  this: AnyObject,
  value: Date | null | undefined,
  context: TestContext<AnyObject>
) {
  const { path, createError, options } = context;
  const { minAgeOfDriver } = options.context as ValidationContext;
  const { birthday } = this.parent;
  const licenseDate = value ? new Date(value) : new Date();
  const minLicenseDate = birthday
    ? new Date(
        birthday.getFullYear() + minAgeOfDriver,
        birthday.getMonth(),
        birthday.getDate() - 1
      )
    : new Date();

  const isValid = checkDateBefore(minLicenseDate, licenseDate);

  if (isValid) {
    return true;
  }

  const minDateOfLicenseError = i18n.t(
    'SMART:WhoIsDriver.errors.minDateOfLicense',
    {
      minAgeOfDriver: minAgeOfDriver,
      defaultValue: errorMessages.minDateOfLicense,
    }
  );

  return createError({
    path,
    message: minDateOfLicenseError,
  });
}

function validateMinYearOfStart(
  this: AnyObject,
  value: string,
  context: TestContext<AnyObject>
) {
  const { path, createError, options } = context;
  const { minAgeOfDriver } = options.context as ValidationContext;
  const { birthday } = this.parent;
  const currentDate = new Date();
  const birthdayYear = birthday
    ? birthday.getFullYear()
    : currentDate.getFullYear();

  const minYearOfStart = Number(birthdayYear) + Number(minAgeOfDriver);

  const isValid = Number(value) >= minYearOfStart;

  if (isValid) {
    return true;
  }

  const minYearOfStartError = i18n.t(
    'SMART:WhoIsDriver.errors.minYearOfStart',
    {
      minYearOfStart: minYearOfStart,
      defaultValue: errorMessages.minYearOfStart,
    }
  );

  return createError({
    path,
    message: minYearOfStartError,
  });
}

const defaultFioSchema = string()
  .required(notFilled)
  .test('max-length', maxLengthMessage, (value) => maxLength.test(value!))
  .test('only-cyrillic', useCyrillic, (value) => onlyCyrillic.test(value!));

export const driver = object({
  name: defaultFioSchema.test('only-letters', nameStartWithLetter, (value) =>
    onlyLetters.test(value!)
  ),
  surname: defaultFioSchema.test(
    'only-letters',
    lastnameStartWithLetter,
    (value) => onlyLetters.test(value!)
  ),
  middlename: string()
    .notRequired()
    .when({
      is: (value: string) => value?.length,
      then: () =>
        defaultFioSchema
          .test('only-letters', middleNameStartWithLetter, (value) =>
            onlyLetters.test(value!)
          )
          .test('only-cyrillic', useCyrillic, (value) =>
            onlyCyrillic.test(value!)
          ),
    }),
  birthday: date()
    .required(notFilled)
    .nullable()
    .test('is-date', notFilled, (value) => value instanceof Date)
    .test('too-young', validateMinAge)
    .test(
      'too-old',
      maxAgeOfDriver,
      (value) => calculateAge(new Date(value!)) < 100
    ),
  license: string()
    .required(notFilled)
    .matches(LICENSE_REG_EXP, incorrectLicense)
    .test('not-all-zeros', incorrectLicense, (value) => {
      if (!value) return false;
      const parts = value.split(' ');
      const number = parts[parts.length - 1];
      return number !== ALL_ZEROS;
    }),
  licenseDate: date()
    .required(notFilled)
    .nullable()
    .test('is-date', notFilled, (value) => value instanceof Date)
    .test('too-young-for-license', validateMinLicenseDate),
  yearOfStart: string()
    .required(notFilled)
    .matches(onlyNumbers, useNumbersMessage)
    .test('too-young-for-start', validateMinYearOfStart)
    .test('incorrect-year-of-start', incorrectYearOfStart, function (value) {
      const currentDate = new Date();
      const { licenseDate } = this.parent;
      const licenseYear = licenseDate
        ? licenseDate.getFullYear()
        : currentDate.getFullYear();

      return (
        Number(value) <= Number(currentDate.getFullYear()) &&
        Number(value) <= Number(licenseYear)
      );
    }),
  id: string().required(),
});

export const schema = object({
  drivers: array(driver).required(),
  forMe: boolean(),
  isValid: boolean(),
});
