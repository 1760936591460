import { formattingNumber, roundUp } from '@shared/utils';

export const renderPrice = (
  value: number,
  activeStep: number,
  maxSteps: number | undefined
): string =>
  activeStep !== maxSteps
    ? formattingNumber(roundUp(value))
    : formattingNumber(value);
