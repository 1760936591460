import { Traveler } from '@smart-components/who-and-how-paperwork/who-and-how-paperwork.types';
import { onlyLatin } from '../constants';

export const checkIsLatin = (data: Traveler) => {
  const isLatinName = onlyLatin.test(data.name);
  const isLatinSurName = onlyLatin.test(data.surname);

  if (
    (!isLatinName && data.name.length > 0) ||
    (!isLatinSurName && data.surname.length > 0)
  ) {
    return false;
  }
  return true;
};
