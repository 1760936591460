/* eslint-disable indent */
import { type FocusEvent, memo, useCallback } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { HelperText } from '@pulse-web-ui/helper-text';
import { DaDataSuggestion, DaDataAddress } from '@pulse-web-ui/dadata';

import type { PolicyholderProps } from './types';

import { Row, RowSection } from './policyholder.styles';
import { DaDataAddressSuggestion, DateField, TextField } from './components';
import {
  PASSPORT_CODE_MASK,
  PASSPORT_CODE_PLACEHOLDER,
  PASSPORT_MASK,
  PASSPORT_PLACEHOLDER,
} from './constants';
import { FIO } from '@shared/constants';
import { getPolicyholderLabel } from './utils';
import {
  WhoIsPolicyholderNames,
  WhoIsPolicyholderValues,
} from '@smart-components/index';
import { capitalizeFirstLetter } from '@shared/utils';

//TODO: доработать, когда будет готов стор и веб-аналитика

export const Policyholder = memo(
  ({ control, clearErrors, getDisableState, setValue }: PolicyholderProps) => {
    const maxBirthdayDate = new Date();
    maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);
    maxBirthdayDate.setDate(maxBirthdayDate.getDate() - 1);

    const maxPassportDate = new Date();

    const handleChange = useCallback(
      <TName extends WhoIsPolicyholderNames, TValue>(
        onChange: ControllerRenderProps<
          WhoIsPolicyholderValues,
          TName
        >['onChange'],
        name: TName
      ) =>
        (value: TValue) => {
          onChange(value);
          clearErrors(name);
        },
      [clearErrors]
    );

    const handleBlurText =
      (name: WhoIsPolicyholderNames) =>
      (event: FocusEvent<HTMLInputElement>) => {
        let newValue = event.target.value.trim();
        if (FIO.has(name)) {
          newValue = capitalizeFirstLetter(newValue);
        }
        setValue(name, newValue);
      };

    const handleChangeAddress = useCallback(
      (
        onChange: ControllerRenderProps['onChange'],
        value?: DaDataSuggestion<DaDataAddress>
      ) => {
        onChange({
          value: value?.value,
          fias_id: value?.data?.fias_id,
          fias_level: value?.data?.fias_level,
        });
        clearErrors('address');
      },
      [clearErrors]
    );

    return (
      <>
        <Row>
          <RowSection>
            <Controller
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <TextField
                  label={getPolicyholderLabel(name)}
                  value={value}
                  onChange={handleChange(onChange, name)}
                  error={error}
                  disabled={getDisableState('surname')}
                  onBlur={handleBlurText(name)}
                  testid="surname"
                />
              )}
              control={control}
              name="surname"
            />
          </RowSection>
          <RowSection>
            <Controller
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <TextField
                  label={getPolicyholderLabel(name)}
                  value={value}
                  onChange={handleChange(onChange, name)}
                  error={error}
                  disabled={getDisableState('name')}
                  onBlur={handleBlurText(name)}
                  testid="name"
                />
              )}
              control={control}
              name="name"
            />
          </RowSection>
        </Row>
        <Row>
          <RowSection>
            <Controller
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <TextField
                  label={getPolicyholderLabel(name)}
                  value={value}
                  onChange={handleChange(onChange, name)}
                  error={error}
                  disabled={getDisableState('middlename')}
                  onBlur={handleBlurText(name)}
                  testid="middlename"
                />
              )}
              control={control}
              name="middlename"
            />
          </RowSection>
          <RowSection>
            <Controller
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <DateField
                  label={getPolicyholderLabel(name)}
                  value={value}
                  onChange={handleChange(onChange, name)}
                  error={error}
                  disabled={getDisableState('birthday')}
                  maxDate={maxBirthdayDate}
                  testid="birthday"
                />
              )}
              control={control}
              name="birthday"
            />
          </RowSection>
        </Row>
        <Row>
          <RowSection>
            <Controller
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <TextField
                  label={getPolicyholderLabel(name)}
                  value={value}
                  error={error}
                  disabled={getDisableState('passport')}
                  onChange={handleChange(onChange, name)}
                  mask={PASSPORT_MASK}
                  placeholder={PASSPORT_PLACEHOLDER}
                  testid="passport"
                />
              )}
              control={control}
              name="passport"
            />
          </RowSection>
          <RowSection>
            <Controller
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <DateField
                  label={getPolicyholderLabel(name)}
                  value={value}
                  onChange={handleChange(onChange, name)}
                  error={error}
                  disabled={getDisableState('passportDate')}
                  maxDate={maxPassportDate}
                  testid="passport-date"
                />
              )}
              control={control}
              name="passportDate"
            />
          </RowSection>
        </Row>
        <Row>
          <RowSection>
            <Controller
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <TextField
                  label={getPolicyholderLabel(name)}
                  value={value}
                  onChange={handleChange(onChange, name)}
                  error={error}
                  disabled={getDisableState('passportByWho')}
                  onBlur={handleBlurText(name)}
                  testid="passport-by-who"
                />
              )}
              control={control}
              name="passportByWho"
            />
          </RowSection>
        </Row>
        <Row>
          <RowSection isHalf>
            <Controller
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <TextField
                  label={getPolicyholderLabel(name)}
                  value={value}
                  error={error}
                  disabled={getDisableState('passportCode')}
                  onChange={handleChange(onChange, name)}
                  mask={PASSPORT_CODE_MASK}
                  placeholder={PASSPORT_CODE_PLACEHOLDER}
                  testid="passport-code"
                />
              )}
              control={control}
              name="passportCode"
            />
          </RowSection>
        </Row>
        <Row>
          <RowSection>
            <Controller
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <HelperText
                  noMargin
                  status={error ? 'error' : 'default'}
                  message={error?.message}
                  data-testid="address"
                >
                  <DaDataAddressSuggestion
                    value={value}
                    onChange={(val) => handleChangeAddress(onChange, val)}
                    selectOnBlur
                    inputProps={{
                      error: !!error,
                      label: getPolicyholderLabel(name),
                      autoComplete: 'nope',
                      disabled: getDisableState('address'),
                    }}
                  />
                </HelperText>
              )}
              control={control}
              name="address"
            />
          </RowSection>
        </Row>
      </>
    );
  }
);
