/* eslint-disable indent */
import { useState, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import type { DocumentType, OnDownloadPDF } from '@pulse-web-ui/documents-list';
import { useMultipleDownloadPdf } from './use-multiple-download-pdf';
import {
  initQueryEnabledList,
  initDocumentsStatusList,
  getHandledResponse,
  getQueryEnabled,
  getStatusList,
  setQueryEnabled,
  removeQuery,
} from './use-handle-download-pdf.utils';
import { isAppleSafari } from '@shared/utils/is-apple-safari';

export const useHandleDownloadPDF = (
  accessToken: string,
  documentsList?: DocumentType[]
) => {
  const isIOS = isAppleSafari();
  const queryClient = useQueryClient();
  // Массив признаков включения запросов.
  const [queryEnabledList, setQueryEnabledList] = useState(
    initQueryEnabledList(documentsList?.length)
  );
  // Массив статусов скачивания для обновления view.
  const [documentsStatusList, setDocumentsStatusList] = useState(
    initDocumentsStatusList(documentsList?.length)
  );

  const handleDownloadClick: OnDownloadPDF = useCallback(
    (index) => setQueryEnabledList(setQueryEnabled(queryEnabledList, index)),
    [queryEnabledList, documentsList]
  );

  // Запускаем скачивание файла у документов с активным признаком включения запроса.
  const results = useMultipleDownloadPdf({
    documentsList,
    queryEnabledList,
    accessToken,
  });

  useEffect(() => {
    // Получаем новые значения статусов скачивания и признаков включения запросов.
    const resultData = results.map(
      getHandledResponse({
        documentsList,
        documentsStatusList,
        queryEnabledList,
        removeQuery: removeQuery(queryClient),
        isIOS,
      })
    );
    if (resultData.some((item) => item.isModified)) {
      setQueryEnabledList(getQueryEnabled(resultData));
      setDocumentsStatusList(getStatusList(resultData));
    }
  }, [results]);

  useEffect(() => {
    // Сбрасываем статусы и признаки включения запросов при получении новых документов.
    setQueryEnabledList(initQueryEnabledList(documentsList?.length));
    setDocumentsStatusList(initDocumentsStatusList(documentsList?.length));
  }, [documentsList]);

  return {
    handleDownloadClick,
    documentsStatusList,
  };
};
