import { VehicleDocument } from '@entities/import-smart-components/vehicle-document';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, type Ref, useMemo, useCallback } from 'react';
import type {
  VehicleDocumentValues,
  SmartComponentAdapterProps,
  VehicleDocumentOptions,
} from '@smart-components/index';
import { DOCUMENTS } from './constants';

//TODO: доработать, когда будет готова веб-аналитика

export const VehicleDocumentAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<VehicleDocumentValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: { updateFormValue },
          productStore: {
            formState: {
              Car,
              WhoIsPolicyholder,
              VehicleDocument: VehicleDocumentData,
            },
          },
        },
      } = useStores();

      const manufactureYear = Car?.car?.manufactureYear?.value;
      const selectedDocument = VehicleDocumentData?.document;

      const handleChangeDocument = useCallback((document: string) => {
        updateFormValue('VehicleDocument', {
          document,
        });
      }, []);

      const options: VehicleDocumentOptions = useMemo(
        () => ({
          documents: DOCUMENTS,
          selectedDocument,
          manufactureYear,
          handleChangeDocument,
        }),
        [DOCUMENTS, selectedDocument, manufactureYear, handleChangeDocument]
      );

      const hasAuthorized = WhoIsPolicyholder?.hasAuthorized;

      if (!hasAuthorized) {
        return null;
      }

      return (
        <Suspense fallback={<FallbackSkeleton height={274} />}>
          <VehicleDocument
            ref={forwardRef}
            value={value}
            options={options}
            onChange={onChange}
            isSubmitting={isSubmitting}
            fieldState={fieldState}
          />
        </Suspense>
      );
    }
  )
);

VehicleDocumentAdapter.displayName = 'VehicleDocumentAdapter';
