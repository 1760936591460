import type { FC, ReactNode } from 'react';
import type { SmartComponentAdapterProps } from '@smart-components/shared/types';

import {
  DocumentsListAdapter,
  FooterAdapter,
  HeaderAdapter,
  InsuranceRisksAdapter,
  SubmitAdapter,
  WantImprovePolicyAdapter,
  WhereAndHowLongAdapter,
  WhoAndHowAdapter,
  WhoAndHowPaperworkAdapter,
  WhoIssuesPolicyAdapter,
  B2PAdapter,
  withCommonSmartComponentAdapter,
  InsurancePeriodAdapter,
  NumberOfInsuredAdapter,
  CoverageSumAdapter,
  InsurancePersonsAdapter,
  CheckPolicyAdapter,
  CarNumberAdapter,
  CarAdapter,
  DriversAdapter,
  InsuranceParametersAdapter,
  WhoIsDriverAdapter,
  WhoIsPolicyholderAdapter,
  VehicleDocumentAdapter,
  PolicyholderDataAdapter,
  CarOwnerAdapter,
  PolicyholderFormAdapter,
} from '@entities/adapters';
import { MockSmartComponent } from './mock-smart-component';

type TypeImportSmartComponents = {
  [key: string]: (props: SmartComponentAdapterProps<any>) => ReactNode;
};

export const importSmartComponents: TypeImportSmartComponents = {
  //TODO: убрать any, как начну разрабатывать логику Car
  Car: CarAdapter,
  Drivers: DriversAdapter,
  InsuranceParameters: InsuranceParametersAdapter,
  InsuranceRisks: InsuranceRisksAdapter,
  WhereAndHowLong: WhereAndHowLongAdapter,
  WhoAndHowPaperwork: WhoAndHowPaperworkAdapter,
  WhoAndHow: WhoAndHowAdapter,
  WantImprovePolicy: WantImprovePolicyAdapter,
  WhoIssuesPolicy: WhoIssuesPolicyAdapter,
  DocumentsList: DocumentsListAdapter,
  B2P: B2PAdapter,
  InsurancePeriod: InsurancePeriodAdapter,
  NumberOfInsured: NumberOfInsuredAdapter,
  CoverageSum: CoverageSumAdapter,
  InsurancePersons: InsurancePersonsAdapter,
  CheckPolicy: CheckPolicyAdapter,
  //TODO: Удалить any, когда поправим типизацию в smart-components-kit
  MockSmartComponent: withCommonSmartComponentAdapter(
    MockSmartComponent as any
  ),
  CarNumber: CarNumberAdapter,
  WhoIsDriver: WhoIsDriverAdapter,
  WhoIsPolicyholder: WhoIsPolicyholderAdapter,
  VehicleDocument: VehicleDocumentAdapter,
  PolicyholderData: PolicyholderDataAdapter,
  PolicyholderForm: PolicyholderFormAdapter,
  CarOwner: CarOwnerAdapter,
};

type TypeImportCommonComponents = {
  [key: string]: FC<any>;
};

export const importCommonComponents: TypeImportCommonComponents = {
  Header: HeaderAdapter,
  Footer: FooterAdapter,
  Submit: SubmitAdapter,
};
