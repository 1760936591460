// regex для телефонов на +7, 8
export const phoneRegEx =
  /^(\+7|8)?[\s\-]?\(?[4890][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

// проверка на отсутствие пробелов, символов и цифр в первом введенном значении
export const onlyLetters = /^[а-яА-Яa-zA-Z]/;
// проверка на количество допустимых символов в поле
export const maxLength = /^.{1,50}$/;
export const maxLength100 = /^.{1,100}$/;
// проверка на использование кириллицы при вводе фио
export const onlyCyrillic = /^[а-яА-ЯЁё\s'-]+$/;

export const promoEx = /^[A-Za-zА-Яа-яЁё0-9. _]{1,20}$/;

export const petNameRegEx = /^[A-Za-zА-Яа-яЁё0-9. _-]{1,40}$/;
// Может начинаться только с Буквы или Цифры. В качестве разделителей допустимо использовать символы пробела, дефис, точка, запятая, двоеточие, дробь.
export const addressRegEx =
  /^[а-яА-ЯёЁ0-9]+([ \-.,:/]?[а-яА-ЯёЁ0-9]+)*[ \-.,:/]?$/;
// Регулярное выражение взято из https://pulse-insure.atlassian.net/wiki/spaces/frontapps/pages/176980098?NO_SSR=1#Email
export const emailRegEx =
  /^(?=.{1,64}@)[A-Za-zА-Яа-я0-9_+-]+(\.[A-Za-zА-Яа-я0-9_+-]+)*@[^-][A-Za-zА-Яа-я0-9-]+(\.[A-Za-zА-Яа-я0-9-]+)*(\.[A-Za-zА-Яа-я]{2,})$/u;

export const onlyNumbers = /^\d+$/;

// только числа от 0 до 90
export const ageRegEx = /^(?:[0-9]|[1-8][0-9]|90)$/;

//regex для ГРЗ
export const CAR_NUMBER_REG_EXP =
  /^[АВЕКМНОРСТУХавекмнорстухFDTRVYJHCNEfdtrvyjhcne]\d{3}[АВЕКМНОРСТУХавекмнорстухFDTRVYJHCNEfdtrvyjhcne]{2}\d{2,3}$/;

export const NOT_DIGIT_REG_EXP = /\D/g;

export const ZERO_AT_START = /^(0[0-9])/;

//regex для серии и номера паспорта
export const PASSPORT_REG_EXP = /^(\d{2}\s?\d{2})( \d{6})$/;

//regex для кода подразделения
export const PASSPORT_CODE_REG_EXP = /^\d{3}-?\d{3}$/;

//regex для серии и номера ВУ
export const LICENSE_REG_EXP = /^(\d{2} [\dА-ЯABEKMHOPCTYX]{2}) (\d{6})$/;

// только цифры
export const DigitsRegEx = /[0-9]/;

// только цифры и кириллица
export const DigitsAndCyrillicRegEx = /[0-9А-ЯABEKMHOPCTYX]/i;

//regex для серии и номера СТС
export const stsRegEx =
  /^([0-9]{2}\s[АВЕКМНОРСТУХABEKMHOPCTYX0-9]{2})\s([0-9]{6})$/;

//regex для серии и номера ПТС
export const ptsRegEx =
  /^([0-9]{2}\s[АВЕКЛМНОРСТУХЮABEKMHOPCTYX0]{2})\s([0-9]{6})$/;

//regex для номера ЭПТС
export const eptsRegEx = /^([1-3][0-9]{3})(0[1-4][0-9]{9})$/;
