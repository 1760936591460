import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { WhoAndHow } from '@entities/import-smart-components/who-and-how';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import type { AnalyticEventsMap } from '@shared/types';
import { observer } from 'mobx-react-lite';
import {
  type FC,
  Suspense,
  useMemo,
  forwardRef,
  type Ref,
  useCallback,
} from 'react';
import type {
  SmartComponentAdapterProps,
  WhoAndHowValues,
  WhoAndHowOptions,
  AgeSelectItem,
} from '@smart-components/index';
import { WhoAndHowAnalyticEvent } from '@smart-components/who-and-how';

const analyticEventsMap: AnalyticEventsMap<WhoAndHowAnalyticEvent> = {
  [WhoAndHowAnalyticEvent.ON_INSURANCE_CHANGE]: {
    name: analyticEvents.travelTravellersSelected,
  },
  [WhoAndHowAnalyticEvent.ON_COVERAGE_SUM_CHANGE]: {
    name: analyticEvents.travelSumSelected,
  },
  [WhoAndHowAnalyticEvent.ON_HAS_SPORT_CHANGE]: {
    name: analyticEvents.travelSport,
  },
  [WhoAndHowAnalyticEvent.ON_SPORT_CHANGE]: {
    name: analyticEvents.travelSportSelected,
  },
};

export const WhoAndHowAdapter: FC<SmartComponentAdapterProps<WhoAndHowValues>> =
  observer(
    forwardRef(
      (
        { value, onChange, isSubmitting, fieldState },
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            initProductStore: { initState },
            productStore: {
              ageRange,
              formState: {
                WhoAndHowPaperwork,
                WhereAndHowLong,
                WhoIssuesPolicy,
              },
              currency,
              setTravelersAges,
            },
            applicationStore: { updateFormValue },
          },
        } = useStores();

        const handleChangeAge = useCallback(
          (travelerAges: AgeSelectItem[]) => {
            const updatedTravelers = setTravelersAges(travelerAges, () => {
              updateFormValue('WhoIssuesPolicy', {
                ...WhoIssuesPolicy,
                forMe: false,
              });
            });

            updateFormValue('WhoAndHowPaperwork', {
              ...WhoAndHowPaperwork,
              travelers: updatedTravelers,
            });
          },
          [WhoAndHowPaperwork, WhoIssuesPolicy]
        );

        const options: WhoAndHowOptions = useMemo(
          () => ({
            maxObjectsNumber: initState?.maxObjectsNumber,
            risks: initState?.risks,
            ageRange,
            currency,
            travelers: WhoAndHowPaperwork?.travelers,
            dateStart: WhereAndHowLong?.travelDates?.start,
            activities: initState?.activities,
            onChangeInsuredPersonsAge: handleChangeAge,
          }),
          [
            initState?.maxObjectsNumber,
            initState?.risks,
            initState?.activities,
            ageRange,
            currency,
            WhoAndHowPaperwork?.travelers,
            WhereAndHowLong?.travelDates?.start,
            handleChangeAge,
          ]
        );

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<WhoAndHowAnalyticEvent>(
            analyticEventsMap
          );

        return (
          <Suspense fallback={<FallbackSkeleton height={280} />}>
            <WhoAndHow
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              fieldState={fieldState}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  );

WhoAndHowAdapter.displayName = 'WhoAndHowAdapter';
