import { forwardRef, memo, Ref, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Block, FormWrapper, InputWrapper } from './drivers.style';
import { i18nDefaultValues } from './i18n';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import { Input } from '@pulse-web-ui/input';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { DriverOptions, DriversValues } from './types';
import { getDriversSchema } from './schema';
import { SmartComponentProps } from '@smart-components/shared/types';
import { NOT_DIGIT_REG_EXP } from '@shared/constants/reg-ex';

export const Drivers = memo(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        options: { minAgeOfDriver, minExperienceOfDriver },
      }: SmartComponentProps<DriversValues, DriverOptions>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();

      const schema = useMemo(() => getDriversSchema(), [t]);

      const {
        watch,
        control,
        trigger,
        formState: { isValid },
        setValue,
      } = useForm<DriversValues>({
        values: value,
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
        context: { minAgeOfDriver, minExperienceOfDriver },
      });

      useEffect(() => {
        setValue('isValid', isValid);
      }, [isValid]);

      useEffect(() => {
        const subscription = watch((values) => {
          onChange(values);
        });

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      const handleChange = useCallback(
        (onChange: (value: number | null) => void) => (value: string) => {
          //Заменяем все не числовые символы
          const onlyNumbers = value.replace(NOT_DIGIT_REG_EXP, '');

          onChange(onlyNumbers === '' ? null : Number(onlyNumbers));
        },
        []
      );

      return (
        <WidgetContainer data-testid="drivers-block" ref={forwardRef}>
          <FormWrapper>
            <HeaderWithSubText
              title={t('SMART:Drivers.title', {
                defaultValue: i18nDefaultValues.Drivers.title,
              })}
            />
            <Block>
              <InputWrapper>
                <Controller
                  name="minDriverAge"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      type="numeric"
                      inputMode="numeric"
                      noMargin
                      maxLength={3}
                      onChange={handleChange(onChange)}
                      error={!!error}
                      hintObject={{ message: error?.message }}
                      label={t('SMART:Drivers.labels.minDriverAge', {
                        defaultValue:
                          i18nDefaultValues.Drivers.labels.minDriverAge,
                      })}
                      value={value == undefined ? '' : String(value)}
                      data-testid="min-driver-age-input"
                    />
                  )}
                />
              </InputWrapper>
              <InputWrapper>
                <Controller
                  name="minDriverExperience"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      type="numeric"
                      inputMode="numeric"
                      noMargin
                      maxLength={3}
                      error={!!error}
                      onChange={handleChange(onChange)}
                      hintObject={{ message: error?.message }}
                      label={t('SMART:Drivers.labels.minDriverExperience', {
                        defaultValue:
                          i18nDefaultValues.Drivers.labels.minDriverExperience,
                      })}
                      value={value == undefined ? '' : String(value)}
                      data-testid="min-driver-experience-input"
                    />
                  )}
                />
              </InputWrapper>
            </Block>
          </FormWrapper>
        </WidgetContainer>
      );
    }
  )
);
