/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, memo, useEffect, useMemo } from 'react';
import type { FC, Ref } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';

import { CoverageSum, InsuredPersons, Sport, SportHas } from './components';
import { CoverageSumType } from './components/coverage-sum/model/types/schema';
import { chooseTravelsQuantityMessage, getWhoAndHowSchema } from './schema';
import { getDefaultValues } from './utils';
import { Form, Row, RowSection } from './who-and-how.style';
import { i18nDefaultValues } from './i18n';
import type {
  Sport as SportType,
  WhoAndHowValues,
  WhoAndHowOptions,
  AgeSelectItem,
} from './who-and-how.type';
import { RISK_VALUE } from '@shared/constants';
import { useTranslation } from 'react-i18next';
import type { TokenInputOption } from '@pulse-web-ui/token-input';
import type { SmartComponentProps } from '@smart-components/shared/types';

export enum WhoAndHowAnalyticEvent {
  ON_INSURANCE_CHANGE,
  ON_COVERAGE_SUM_CHANGE,
  ON_HAS_SPORT_CHANGE,
  ON_SPORT_CHANGE,
}

const WhoAndHow: FC<
  SmartComponentProps<WhoAndHowValues, WhoAndHowOptions, WhoAndHowAnalyticEvent>
> = memo(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        options: {
          maxObjectsNumber,
          risks,
          ageRange,
          currency,
          dateStart,
          travelers,
          activities,
          onChangeInsuredPersonsAge,
        },
        onAnalyticEventSend,
      },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();
      const schema = useMemo(() => getWhoAndHowSchema(), [t]);

      const {
        trigger,
        setError: innerSetError,
        control,
        getValues,
        resetField,
        watch,
        setValue,
        formState: { errors, isValid },
      } = useForm<WhoAndHowValues>({
        defaultValues: getDefaultValues(
          value as WhoAndHowValues,
          travelers,
          dateStart
        ),
        mode: 'all',
        shouldFocusError: true,
        resolver: yupResolver(schema),
        context: {
          ageRange,
          dateStart,
        },
      });

      const coverageSumArray = useMemo<Array<number> | undefined>(
        () =>
          risks?.find((item: CoverageSumType) => item.code === RISK_VALUE)
            ?.coverageSum,
        [risks]
      );

      const handleChangeInsurance =
        (onChange: (value: AgeSelectItem[]) => void) =>
        (value: AgeSelectItem[]) => {
          onChange(value);
          onChangeInsuredPersonsAge(value);
          onAnalyticEventSend &&
            onAnalyticEventSend(WhoAndHowAnalyticEvent.ON_INSURANCE_CHANGE);
        };

      const handleChangeCoverageSum =
        (onChange: (value: string) => void) =>
        (value: string, isMounting?: boolean) => {
          onChange(value);
          !isMounting &&
            onAnalyticEventSend &&
            onAnalyticEventSend(WhoAndHowAnalyticEvent.ON_COVERAGE_SUM_CHANGE);
        };

      const handleChangeSporthas =
        (onChange: (value: boolean) => void) => (value: boolean) => {
          onChange(value);
          onAnalyticEventSend &&
            onAnalyticEventSend(WhoAndHowAnalyticEvent.ON_HAS_SPORT_CHANGE);
        };

      const handleChangeSports =
        (onChange: (value: TokenInputOption[]) => void) =>
        (value: TokenInputOption[]) => {
          onChange(value);

          if (value.length) {
            onAnalyticEventSend &&
              onAnalyticEventSend(WhoAndHowAnalyticEvent.ON_SPORT_CHANGE);
          }
        };

      useEffect(() => {
        setValue('isValid', isValid);
      }, [isValid]);

      useEffect(() => {
        const subscription = watch((value) => {
          onChange(value as WhoAndHowValues);
        });

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        const ageSelect = getValues('ageSelect');
        if (!ageSelect) return;
        if (ageSelect.length === 0) {
          innerSetError('ageSelect', {
            message: chooseTravelsQuantityMessage,
          });
        }
      }, [watch('ageSelect'), isSubmitting]);

      useEffect(() => {
        const isSportChecked = getValues('sportHas');

        if (!isSportChecked) {
          resetField('sportKinds');
        }
      }, [watch('sportHas')]);

      useEffect(() => {
        const ageSelect = getValues('ageSelect');
        if (
          value?.ageSelect &&
          ageSelect?.length !== value?.ageSelect?.length
        ) {
          setValue('ageSelect', value.ageSelect);
          trigger('ageSelect');
        }
      }, [value?.ageSelect]);

      return (
        <Form ref={forwardRef} data-test="Who-and-how-block">
          <HeaderWithSubText
            title={t('SMART:WhoAndHow.title', {
              defaultValue: i18nDefaultValues.WhoAndHow.title,
            })}
            subTitle={t('SMART:WhoAndHow.subTitle', {
              count: maxObjectsNumber,
              defaultValue: i18nDefaultValues.WhoAndHow.subTitle,
            })}
          />
          <Row>
            <RowSection>
              <Controller
                control={control}
                name="ageSelect"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <InsuredPersons
                    value={value}
                    onChange={handleChangeInsurance(onChange)}
                    error={error}
                    errors={errors}
                    maxPersons={maxObjectsNumber}
                  />
                )}
              />
            </RowSection>
            <RowSection>
              <Controller
                control={control}
                name="coverageSum"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div data-test="Insurance-sum">
                    <CoverageSum
                      coverageSum={coverageSumArray}
                      currency={currency}
                      value={value}
                      onChange={handleChangeCoverageSum(onChange)}
                      error={error}
                    />
                  </div>
                )}
              />
            </RowSection>
          </Row>
          <Controller
            control={control}
            name="sportHas"
            render={({ field: { onChange, value } }) => (
              <SportHas
                value={Boolean(value)}
                onChange={handleChangeSporthas(onChange)}
              />
            )}
          />
          <Controller
            control={control}
            name="sportKinds"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return getValues('sportHas') ? (
                <Sport
                  value={value as SportType[]}
                  onChange={handleChangeSports(onChange)}
                  error={error}
                  activities={activities}
                />
              ) : (
                <></>
              );
            }}
          />
        </Form>
      );
    }
  )
);

export default WhoAndHow;
