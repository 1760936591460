import { Endpoints, PRODUCT_CODE } from '@shared/constants';
import { useQuery } from 'react-query';
import { fetchData } from '@shared/utils';
import type { RisksResponse } from '@shared/queries';

// TODO: доработать, когда будут известны зависимости
// и будет code продукта в store

export const useGetRisks = (enabled: boolean) => {
  return useQuery(
    ['get-risks'],
    fetchData<RisksResponse>({
      url: Endpoints.GET_RISKS,
      method: 'POST',
      data: {
        baseParameters: {
          productVersion: `${PRODUCT_CODE},1`,
        },
      },
    }),
    { enabled }
  );
};
