import {
  type Ref,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Controller,
  type ControllerRenderProps,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { SmartComponentProps } from '@smart-components/index';
import type {
  ValidationContext,
  VehicleDocumentNames,
  VehicleDocumentOptions,
  VehicleDocumentValues,
} from './types';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { Container, Form, Row, RowSection } from './vehicle-document.styles';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import { i18nDefaultValues } from './i18n';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { Select } from '@pulse-web-ui/select';
import { getLabel, getMask, getPlaceholder } from './utils';

//TODO: доработать, когда будет готова веб-аналитика

export const VehicleDocument = memo(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        options: {
          documents,
          selectedDocument,
          manufactureYear,
          handleChangeDocument,
        },
      }: SmartComponentProps<VehicleDocumentValues, VehicleDocumentOptions>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();

      const {
        control,
        trigger,
        setValue,
        formState: { isValid },
        watch,
        clearErrors,
      } = useForm<VehicleDocumentValues, ValidationContext>({
        values: value,
        mode: 'onSubmit',
        resolver: yupResolver(schema),
        context: { manufactureYear },
      });

      const defaultDocument = selectedDocument ?? documents[0].value;
      const [document, setDocument] = useState(defaultDocument);
      const [documentLabel, setDocumentLabel] = useState(defaultDocument);

      useEffect(() => {
        // значение по умолчанию
        setValue('document', defaultDocument);
      }, []);

      useEffect(() => {
        const curDocument = documents.find((item) => item.value === document);

        setDocumentLabel((prev) => curDocument?.label ?? prev);
      }, [document]);

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        const subscription = watch((values) => {
          onChange(values as VehicleDocumentValues);
        });

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        setValue('isValid', isValid);
      }, [isValid]);

      const onDocumentChange = useCallback(
        (onChange: ControllerRenderProps['onChange']) => (value: string) => {
          onChange(value);
          setDocument(value);
          setValue('documentNumber', '');
          setValue('documentDate', null);
          handleChangeDocument(value);
        },
        []
      );

      const handleChange = useCallback(
        <TName extends VehicleDocumentNames, TValue>(
          onChange: ControllerRenderProps<
            VehicleDocumentValues,
            TName
          >['onChange'],
          name: TName
        ) =>
          (value: TValue) => {
            onChange(value);
            clearErrors(name);
          },
        [clearErrors]
      );

      const handlePipe = useCallback((conformedValue: string) => {
        return conformedValue.toUpperCase();
      }, []);

      const currentDate = new Date();

      return (
        <WidgetContainer ref={forwardRef} data-testid="vehicle-document">
          <Form>
            <HeaderWithSubText
              title={t('SMART:VehicleDocument.title', {
                defaultValue: i18nDefaultValues.VehicleDocument.title,
              })}
            />
            <Container>
              <Row>
                <RowSection>
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <Select
                        value={value}
                        onChange={onDocumentChange(onChange)}
                        options={documents}
                        label={t('SMART:VehicleDocument.labels.document', {
                          defaultValue:
                            i18nDefaultValues.VehicleDocument.labels.document,
                        })}
                        placeholder={t(
                          'SMART:VehicleDocument.labels.document',
                          {
                            defaultValue:
                              i18nDefaultValues.VehicleDocument.labels.document,
                          }
                        )}
                        data-testid="document"
                      />
                    )}
                    control={control}
                    name="document"
                  />
                </RowSection>
                <RowSection>
                  <Controller
                    render={({
                      field: { name, onChange, value },
                      fieldState: { error },
                    }) => (
                      <HelperText
                        noMargin
                        status={error ? 'error' : 'default'}
                        message={error?.message}
                      >
                        <Input
                          noMargin
                          label={getLabel(document, documentLabel)}
                          value={value}
                          error={!!error}
                          onChange={handleChange(onChange, name)}
                          mask={getMask(document)}
                          placeholder={getPlaceholder(document)}
                          pipe={handlePipe}
                          data-testid="document-number"
                        />
                      </HelperText>
                    )}
                    control={control}
                    name="documentNumber"
                  />
                </RowSection>
              </Row>
              <Row>
                <RowSection isHalf>
                  <Controller
                    render={({
                      field: { name, onChange, value },
                      fieldState: { error },
                    }) => (
                      <HelperText
                        noMargin
                        status={error ? 'error' : 'default'}
                        message={error?.message}
                      >
                        <Datepicker
                          onChange={handleChange(onChange, name)}
                          showYearDropdown
                          showMonthDropdown
                          disabledKeyboardNavigation
                          showInput
                          selected={value}
                          maxDate={currentDate}
                          label={t(
                            'SMART:VehicleDocument.labels.documentDate',
                            {
                              document: documentLabel,
                              defaultValue:
                                i18nDefaultValues.VehicleDocument.labels
                                  .documentDate,
                            }
                          )}
                          error={!!error}
                          data-testid="document-date"
                        />
                      </HelperText>
                    )}
                    control={control}
                    name="documentDate"
                  />
                </RowSection>
              </Row>
            </Container>
          </Form>
        </WidgetContainer>
      );
    }
  )
);
