import { Passport } from '@shared/types';

export enum AuthBoxAnalyticEvent {
  ON_REGISTRATION_SUCCESS,
  ON_SIGNUP_SUCCESS,
  ON_ENTER_CODE_SUCCESS,
}

export type OnAnalyticEventSend = VoidFn<AuthBoxAnalyticEvent>;

export type AuthBoxType = {
  registrationData: RegistrationRequestDataType;
  whoIsPolicyholderData: WhoIsPolicyholderData;
  updateFormValue: (fieldName: string, value: any) => void;
  setFormValueError: (fieldName: string, error: any) => void;
  setIsRegistration: (isRegistration: boolean) => void;
  onAnalyticEventSend?: OnAnalyticEventSend;
};

export type AuthenticationResponseType = {
  authenticationToken: string;
  confirmationId: string;
};

export type RegistrationRequestDataType = {
  user: {
    firstName: string;
    lastName: string;
    middleName: string;
    passport: Passport;
    extendedIntermediaryChannelCode: string;
    clientCategory: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    utmContent: string;
    wmId: string;
    birthDay: string;
    email: string;
    userPolicies: { approvedAt: Date; code: string }[];
  };
};

export type WhoIssuesPolicyDataType = {
  email: string;
  birthDate: Date;
  client: boolean;
  forMe: boolean;
  hasAuthorized: boolean;
  promoAgreement: boolean;
  phone: string;
  showSwitcher: boolean;
  isValid: boolean;
};

export interface WhoIsPolicyholderData {
  birthday: Date;
  email: string;
  phone: string;
  hasAuthorized: boolean;
  client: boolean;
  forMe: boolean;
  isValid: boolean;
}
