import { WhoIsPolicyholder } from '@entities/import-smart-components/who-is-policyholder';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useMemo } from 'react';
import type { Ref } from 'react';
import type {
  SmartComponentAdapterProps,
  WhoIsPolicyholderValues,
  WhoIsPolicyholderOptions,
} from '@smart-components/index';

//TODO: доработать, когда будет готов стор и веб-аналитика

export const WhoIsPolicyholderAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<WhoIsPolicyholderValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: { deviceType, phoneFromMobileApp },
          authStore: {
            disableProfileState,
            disableForm,
            otpErrorCode,
            handleAuth,
            handleOtp,
            setRefetchAuthentication,
            isUserNotDefine,
            setIsUserNotDefine,
            setIsFastAuthOpen,
          },
          productStore: { agentLogin },
        },
      } = useStores();

      const options: WhoIsPolicyholderOptions = useMemo(
        () => ({
          deviceType,
          phoneFromMobileApp,
          agentLogin,
          disableProfileState,
          isOtpError: otpErrorCode,
          handleAuth,
          handleOtp,
          setRefetchAuthentication,
          isUserNotDefine,
          setIsUserNotDefine,
          setIsFastAuthOpen,
        }),
        [
          deviceType,
          phoneFromMobileApp,
          agentLogin,
          otpErrorCode,
          handleAuth,
          handleOtp,
          setRefetchAuthentication,
          isUserNotDefine,
          setIsUserNotDefine,
          setIsFastAuthOpen,
        ]
      );

      return (
        <Suspense fallback={<FallbackSkeleton height={340} />}>
          <WhoIsPolicyholder
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            fieldState={fieldState}
            disabled={disableForm}
          />
        </Suspense>
      );
    }
  )
);

WhoIsPolicyholderAdapter.displayName = 'WhoIsPolicyholderAdapter';
