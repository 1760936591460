/* eslint-disable indent */
import { Suspense, forwardRef, useEffect, useMemo, type Ref } from 'react';
import { observer } from 'mobx-react-lite';

import type {
  PolicyholderDataOptions,
  SmartComponentAdapterProps,
} from '@smart-components/index';
import { CheckPolicy } from '@entities/import-smart-components/check-policy';
import type {
  CheckPolicyOptions,
  CheckPolicyValues,
} from '@pulse-smart-components-kit/check-policy';

import { Descriptions } from '@pulse-web-ui/descriptions';
import {
  CarDataView,
  InsuranceRisksAccordion,
  Loader,
  TotalPriceAccordion,
} from './components';
import { schema } from './schema';
import {
  useGetDescriptionItems,
  useHandleDownloadPDF,
  useStores,
} from '@shared/hooks';
import { useTranslation } from 'react-i18next';
import { i18nDefaultValues } from './i18n';
import {
  getFormattedPassportCode,
  getRuFormattedDate,
  phoneDisplayValueCasting,
} from '@shared/utils';
import { DriverOptions } from './types';
import { useGetDriverDescriptionItems } from './hooks';
import { useGetRequestData } from '../submit-adapter/hooks';
import { useHandleSubmit } from '../submit-adapter/queries';

export const CheckPolicyAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<CheckPolicyValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: { paymenStep, setDisabledSubmit },
          productStore: {
            formState: {
              Car,
              InsuranceRisks,
              PolicyholderForm,
              CarOwner,
              WhoIsDriver,
              InsuranceParameters,
            },
            orderData,
            setOrderData,
          },
          authStore: { disableForm, accessToken },
        },
      } = useStores();

      const data = useGetRequestData();
      const { data: submitData, isLoading } = useHandleSubmit(data);

      useEffect(() => {
        if (submitData) {
          setOrderData(submitData);
        }
      }, [submitData]);

      const { t } = useTranslation();

      const { handleDownloadClick, documentsStatusList } = useHandleDownloadPDF(
        accessToken || '',
        orderData?.clientDocument
      );

      const isAcceptCheckboxDisabled = disableForm || paymenStep;

      const carDataViewProps = useMemo(() => {
        const brand = Car?.car?.brand?.label;
        const model = Car?.car?.model?.label;
        const year = Car?.car?.manufactureYear?.label;
        const power = Car?.car?.power?.label;

        return {
          title: `${brand} ${model}`,
          desription: t('SMART:CheckPolicyAdapter.carDataView.description', {
            defaultValue:
              i18nDefaultValues.CheckPolicyAdapter.carDataView.description,
            year,
            power,
          }),
        };
      }, [Car?.car]);

      const totalPriceProps = useMemo(() => {
        const {
          productProperty: {
            insuranceSum,
            insuranceObject: { region, stoType, franchise },
          },
          baseParameters: { productCode },
        } = data;

        return { insuranceSum, region, stoType, franchise, productCode };
      }, [data]);

      const insuranceRisksProps = useMemo(() => {
        return InsuranceRisks?.insuranceRisks
          ?.filter((risk) => risk.binding || risk.checked)
          .map((risk) => {
            return { title: risk.name };
          });
      }, [InsuranceRisks?.insuranceRisks]);

      const policyholderOptions: PolicyholderDataOptions = useMemo(() => {
        return {
          middlename: PolicyholderForm?.middlename ?? '',
          name: PolicyholderForm?.name,
          surname: PolicyholderForm?.surname,
          birthday: !!PolicyholderForm?.birthday
            ? getRuFormattedDate(PolicyholderForm?.birthday)
            : undefined,
          phone: !!PolicyholderForm?.phone
            ? phoneDisplayValueCasting(PolicyholderForm?.phone)
            : undefined,
          email: PolicyholderForm?.email,
          address: PolicyholderForm?.address?.value,
          passport: PolicyholderForm?.passport,
          passportCode: !!PolicyholderForm?.passportCode
            ? getFormattedPassportCode(PolicyholderForm?.passportCode)
            : undefined,
          passportByWho: PolicyholderForm?.passportByWho,
          passportDate: !!PolicyholderForm?.passportDate
            ? getRuFormattedDate(PolicyholderForm?.passportDate)
            : undefined,
        };
      }, [
        PolicyholderForm?.birthday,
        PolicyholderForm?.middlename,
        PolicyholderForm?.name,
        PolicyholderForm?.surname,
        PolicyholderForm?.passport,
        PolicyholderForm?.passportDate,
        PolicyholderForm?.passportByWho,
        PolicyholderForm?.passportCode,
        PolicyholderForm?.address?.value,
        PolicyholderForm?.phone,
        PolicyholderForm?.email,
      ]);

      const carOwnerOptions: PolicyholderDataOptions = useMemo(() => {
        return {
          middlename: CarOwner?.middlename ?? '',
          name: CarOwner?.name,
          surname: CarOwner?.surname,
          birthday: !!CarOwner?.birthday
            ? getRuFormattedDate(CarOwner?.birthday)
            : undefined,
          address: CarOwner?.address?.value,
          passport: CarOwner?.passport,
          passportCode: !!CarOwner?.passportCode
            ? getFormattedPassportCode(CarOwner?.passportCode)
            : undefined,
          passportByWho: CarOwner?.passportByWho,
          passportDate: !!CarOwner?.passportDate
            ? getRuFormattedDate(CarOwner?.passportDate)
            : undefined,
        };
      }, [
        CarOwner?.birthday,
        CarOwner?.middlename,
        CarOwner?.name,
        CarOwner?.surname,
        CarOwner?.passport,
        CarOwner?.passportDate,
        CarOwner?.passportByWho,
        CarOwner?.passportCode,
        CarOwner?.address?.value,
      ]);

      const driverOptions: DriverOptions[] = useMemo(() => {
        return (
          WhoIsDriver?.drivers?.map((driver) => {
            return {
              middlename: driver?.middlename ?? '',
              name: driver?.name,
              surname: driver?.surname,
              birthday: !!driver?.birthday
                ? getRuFormattedDate(driver?.birthday)
                : undefined,
              license: driver?.license,
              yearOfStart: driver?.yearOfStart,
            };
          }) ?? []
        );
      }, [WhoIsDriver?.drivers]);

      const policyStartDate = useMemo(() => {
        return !!InsuranceParameters?.period
          ? getRuFormattedDate(InsuranceParameters.period.startDate)
          : '';
      }, [InsuranceParameters?.period?.startDate]);

      const policyholderDescriptionItems =
        useGetDescriptionItems(policyholderOptions);
      const carOwnerDescriptionItems = useGetDescriptionItems(carOwnerOptions);
      const driversDescriptionItems =
        useGetDriverDescriptionItems(driverOptions);

      const options: CheckPolicyOptions = useMemo(
        () => ({
          insuranceEntitiesConfig: {
            items: [
              {
                key: 1,
                description: (
                  <Descriptions
                    title={t('SMART:CheckPolicyAdapter.headers.policyholder', {
                      defaultValue:
                        i18nDefaultValues.CheckPolicyAdapter.headers
                          .policyholder,
                    })}
                    items={policyholderDescriptionItems}
                    columnsCount={2}
                  />
                ),
              },
              {
                key: 2,
                description: (
                  <Descriptions
                    title={t('SMART:CheckPolicyAdapter.headers.carOwner', {
                      defaultValue:
                        i18nDefaultValues.CheckPolicyAdapter.headers.carOwner,
                    })}
                    items={carOwnerDescriptionItems}
                    columnsCount={2}
                  />
                ),
              },
              ...driversDescriptionItems,
              {
                key: 3,
                description: (
                  <Descriptions
                    items={[
                      {
                        title: t(
                          'SMART:CheckPolicyAdapter.headers.policyStartDate',
                          {
                            defaultValue:
                              i18nDefaultValues.CheckPolicyAdapter.headers
                                .policyStartDate,
                          }
                        ),
                        description: policyStartDate,
                        key: 1,
                      },
                    ]}
                  />
                ),
              },
            ],
          },
          documentsList: orderData?.clientDocument,
          onDownload: handleDownloadClick,
          documentsStatusList,
          additionalComponent: (
            <>
              <CarDataView
                title={carDataViewProps.title}
                description={carDataViewProps.desription}
              />
              <TotalPriceAccordion
                insuranceSum={totalPriceProps.insuranceSum}
                regionCode={totalPriceProps.region}
                repairType={totalPriceProps.stoType}
                franchise={totalPriceProps.franchise?.franchiseValue ?? 0}
                productCode={totalPriceProps.productCode}
              />
              <InsuranceRisksAccordion risks={insuranceRisksProps ?? []} />
            </>
          ),
        }),
        [
          policyStartDate,
          policyholderDescriptionItems,
          carOwnerDescriptionItems,
          driversDescriptionItems,
          carDataViewProps,
          totalPriceProps,
          insuranceRisksProps,
          submitData,
          handleDownloadClick,
          documentsStatusList,
        ]
      );

      useEffect(() => {
        setDisabledSubmit(isLoading);

        return () => {
          setDisabledSubmit(false);
        };
      }, [isLoading]);

      if (isLoading) {
        return <Loader />;
      }

      return (
        <Suspense>
          <CheckPolicy
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={isAcceptCheckboxDisabled}
            fieldState={fieldState}
            formSchema={schema}
          />
        </Suspense>
      );
    }
  )
);
