export enum Document {
  STS = '31',
  PTS = '30',
  EPTS = '41',
}

export const STS_PLACEHOLDER = '00 ХХ 000000';
export const PTS_PLACEHOLDER = '00 ХХ 000000';
export const EPTS_PLACEHOLDER = '0'.repeat(15);

export const STS_MASK = [
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[АВЕКМНОРСТУХABEKMHOPCTYX0-9]/i,
  /[АВЕКМНОРСТУХABEKMHOPCTYX0-9]/i,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const PTS_MASK = [
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[АВЕКЛМНОРСТУХЮABEKMHOPCTYX0]/i,
  /[АВЕКЛМНОРСТУХЮABEKMHOPCTYX0]/i,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const EPTS_MASK = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
