import type { FormBuilderUpdateHandler } from '@features/form-builder';
import { useStores } from '@shared/hooks';
import { differenceInYears } from 'date-fns';
import { CascoStep } from 'mock/product-config/types';
import { useEffect } from 'react';

export const useHandleWhoIssuesPolicy = (
  updateFormValue: FormBuilderUpdateHandler
) => {
  const {
    MainStore: {
      applicationStore: { activeStepHasName, activeStep },
      productStore: { formState },
    },
  } = useStores();

  useEffect(() => {
    if (activeStepHasName(CascoStep.CALCULATION)) {
      const whoIssuesPolicy = formState?.WhoIssuesPolicy;
      const userBirthdate = whoIssuesPolicy?.birthDate;
      const firstTravelerBirthday =
        formState?.WhoAndHowPaperwork?.travelers?.[0]?.birthday;
      const userAge = differenceInYears(new Date(), userBirthdate);
      const firstTravelerAge = differenceInYears(
        new Date(),
        firstTravelerBirthday
      );

      updateFormValue('WhoIssuesPolicy', {
        ...whoIssuesPolicy,
        forMe: userAge === firstTravelerAge,
      });
    }
  }, [activeStep]);
};
