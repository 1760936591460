import type { InsuranceProduct } from '@shared/types';
import { Endpoints, PRODUCT_CODE } from '@shared/constants';
import { useQuery } from 'react-query';
import { fetchData } from '@shared/utils';
import { useStores } from '@shared/hooks';
import { useEffect } from 'react';

export const useHandleProduct = () => {
  const {
    MainStore: {
      initProductStore: { initState, setInitState },
    },
  } = useStores();

  const { refetch, isLoading, data } = useQuery(
    ['get-insurance-product'],
    fetchData<InsuranceProduct>({
      url: Endpoints.GET_INSURANCE_PRODUCT,
      method: 'POST',
      data: { productCode: PRODUCT_CODE },
    }),
    { cacheTime: Infinity, staleTime: Infinity }
  );

  useEffect(() => {
    if (!isLoading && data) {
      setInitState({ ...initState, ...data });
    }
  }, [isLoading, data]);

  return refetch;
};
