import type { Card } from '@pulse-smart-components-kit/payment/lib/types';
import { DocumentPolicy, MessagePolicy, Ruble } from '@pulse-web-ui/icons';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

const SuccessfulPaymentComponent = lazy(() =>
  import('@pulse-smart-components-kit/payment/lib/successful-payment').then(
    (module) => ({
      default: module.SuccessfulPayment,
    })
  )
);

export const SuccessPayment = () => {
  const { t } = useTranslation();
  const cards: Card[] = [
    {
      id: 1,
      icon: <Ruble width={64} />,
      description: t('COMMON:pay.description-1'),
    },
    {
      id: 2,
      icon: <MessagePolicy width={64} />,
      description: t('COMMON:pay.description-2'),
    },
    {
      id: 3,
      icon: <DocumentPolicy width={64} />,
      description: t('COMMON:pay.description-3'),
    },
  ];

  return (
    <Suspense fallback="">
      <SuccessfulPaymentComponent
        onClick={() => console.log('handle click')}
        email="test@gmail.com"
        date="c 1 сентября 2024 года"
        cards={cards}
      />
    </Suspense>
  );
};
