/* eslint-disable indent */
import { memo, useCallback, type FocusEvent } from 'react';
import { Row, RowSection } from './phone-and-email.styles';
import {
  Controller,
  ControllerRenderProps,
  Path,
  PathValue,
} from 'react-hook-form';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';
import { i18nDefaultValues } from './i18n';
import { useTranslation } from 'react-i18next';
import {
  PhoneAndEmailDisableState,
  PhoneAndEmailForm,
  PhoneAndEmailProps,
} from './types';

const PhoneAndEmailWithoutMemo = <
  T extends PhoneAndEmailForm,
  E extends PhoneAndEmailDisableState,
>({
  control,
  clearErrors,
  setValue,
  getDisableState,
}: PhoneAndEmailProps<T, E>) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (onChange: ControllerRenderProps<T>['onChange'], name: Path<T>) =>
      (value: string) => {
        onChange(value);
        clearErrors(name);
      },
    [clearErrors]
  );

  const handleBlurEmail = useCallback((event: FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();
    setValue('email' as Path<T>, newValue as PathValue<T, Path<T>>);
  }, []);

  return (
    <Row>
      <RowSection>
        <Controller
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <HelperText
              noMargin
              status={error ? 'error' : 'default'}
              message={
                error?.message ??
                t('SMART:PhoneAndEmail.hints.sendByPhone', {
                  defaultValue:
                    i18nDefaultValues.PhoneAndEmail.hints.sendByPhone,
                })
              }
            >
              <Input
                type="tel"
                label={t('SMART:PhoneAndEmail.labels.phone', {
                  defaultValue: i18nDefaultValues.PhoneAndEmail.labels.phone,
                })}
                value={value}
                onChange={handleChange(onChange, name)}
                error={!!error}
                disabled={getDisableState('phone')}
                data-testid="phone"
              />
            </HelperText>
          )}
          control={control}
          name={'phone' as Path<T>}
        />
      </RowSection>
      <RowSection>
        <Controller
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <HelperText
              status={error ? 'error' : 'default'}
              message={
                error?.message ??
                t('SMART:PhoneAndEmail.hints.sendByMail', {
                  defaultValue:
                    i18nDefaultValues.PhoneAndEmail.hints.sendByMail,
                })
              }
            >
              <Input
                onChange={handleChange(onChange, name)}
                value={value}
                error={!!error}
                disabled={getDisableState('email')}
                label={t('SMART:PhoneAndEmail.labels.email', {
                  defaultValue: i18nDefaultValues.PhoneAndEmail.labels.email,
                })}
                onBlur={handleBlurEmail}
                data-testid="email"
              />
            </HelperText>
          )}
          control={control}
          name={'email' as Path<T>}
        />
      </RowSection>
    </Row>
  );
};

export const PhoneAndEmail = memo(
  PhoneAndEmailWithoutMemo
) as typeof PhoneAndEmailWithoutMemo;
