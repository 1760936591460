import { WhoIsDriver } from '@entities/import-smart-components/who-is-driver';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useCallback, useEffect, useMemo } from 'react';
import type { Ref } from 'react';
import type {
  SmartComponentAdapterProps,
  WhoIsDriverOptions,
  WhoIsDriverValues,
} from '@smart-components/index';
import { MIN_AGE_OF_DRIVER, MIN_NUMBER_OF_DRIVERS } from './constants';
import { Driver } from '@shared/types';
import { v4 as uuid } from 'uuid';

export const WhoIsDriverAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<WhoIsDriverValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          initProductStore: {
            initState: { extraParams },
          },
          productStore: {
            formState: { WhoIsPolicyholder, WhoIsDriver: WhoIsDriverData },
          },
          applicationStore: { updateFormValue },
        },
      } = useStores();

      const hasAuthorized = WhoIsPolicyholder?.hasAuthorized;
      const isMe = WhoIsPolicyholder?.forMe;

      useEffect(() => {
        let drivers: Driver[] = WhoIsDriverData?.drivers || [];

        const me: Driver = {
          ...WhoIsDriverData?.drivers?.[0],
          name: WhoIsPolicyholder?.name || '',
          surname: WhoIsPolicyholder?.surname || '',
          middlename: WhoIsPolicyholder?.middlename || '',
          birthday: WhoIsPolicyholder?.birthday || null,
          id: drivers?.[0]?.id || uuid(),
        };

        if (isMe) {
          drivers = [me, ...drivers.slice(1)];
        }

        updateFormValue('WhoIsDriver', {
          ...WhoIsDriverData,
          drivers: drivers,
        });
      }, [
        isMe,
        WhoIsPolicyholder?.name,
        WhoIsPolicyholder?.surname,
        WhoIsPolicyholder?.birthday,
      ]);

      const handleChangeForMe = useCallback(
        (value?: boolean) => {
          if (value !== undefined) {
            updateFormValue('WhoIsPolicyholder', {
              ...WhoIsPolicyholder,
              forMe: value,
            });
          }
        },
        [WhoIsPolicyholder]
      );

      const handleDeleteMyself = useCallback(() => {
        updateFormValue('WhoIsPolicyholder', {
          ...WhoIsPolicyholder,
          forMe: false,
        });
      }, [WhoIsPolicyholder]);

      const options: WhoIsDriverOptions = useMemo(() => {
        const minAgeOfDriver = Number(
          extraParams?.find(
            (extraParam) => extraParam.code === MIN_AGE_OF_DRIVER
          )?.value
        );
        const maxDriversNumber = Number(
          extraParams?.find(
            (extraParam) => extraParam.code === MIN_NUMBER_OF_DRIVERS
          )?.value
        );

        return {
          isMe: WhoIsPolicyholder?.forMe,
          minAgeOfDriver,
          maxDriversNumber,
          hasAuthorized,
          onDeleteMyself: handleDeleteMyself,
          onChangeForMe: handleChangeForMe,
        };
      }, [
        extraParams,
        hasAuthorized,
        WhoIsPolicyholder?.forMe,
        handleDeleteMyself,
        handleChangeForMe,
      ]);

      if (!hasAuthorized || !value) {
        return null;
      }

      return (
        <Suspense>
          <WhoIsDriver
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            fieldState={fieldState}
          />
        </Suspense>
      );
    }
  )
);

WhoIsDriverAdapter.displayName = 'WhoIsDriverAdapter';
