import { DEFAULT_RISK_CODE } from '../../entities/adapters/footer-adapter/constants';
import type { InsuranceRisksValues } from '@smart-components/insurance-risks/insurance-risks.types';

interface Params {
  insuranceRisks?: InsuranceRisksValues;
  amount: number;
}

export const getRisks = ({ insuranceRisks, amount }: Params) => {
  if (!insuranceRisks?.insuranceRisks?.length) {
    return [
      {
        code: DEFAULT_RISK_CODE,
        amount,
      },
    ];
  }

  return insuranceRisks.insuranceRisks
    .filter((item) => item.binding || item?.checked)
    .map((item) => ({ code: item.code as string, amount })); //TODO нужно добавить тип для insuranceRisks
};
