import { DEFAULT_DRIVER } from '../constants';
import type { WhoIsDriverValues } from '../types';

export const getDefaultValues = (
  agesLength: number,
  value?: WhoIsDriverValues
) => {
  const driversLength = value?.drivers?.length ?? 0;

  if (driversLength >= agesLength) {
    return value;
  }

  const newDrivers = Array(agesLength - driversLength).fill(DEFAULT_DRIVER);

  return {
    drivers: [...(value?.drivers ?? []), ...newDrivers],
  };
};
