import { yupResolver } from '@hookform/resolvers/yup';
import {
  Suspense,
  forwardRef,
  lazy,
  memo,
  useEffect,
  useState,
  useLayoutEffect,
} from 'react';
import type { FC, Ref } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Button } from '@pulse-web-ui/button';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';

import { Traveler } from './components';
import { schema } from './schema';
import { DEFAULT_TRAVELER } from './constants';
import { Container, Form } from './who-and-how-paperwork.styles';
import { i18nDefaultValues } from './i18n';
import type {
  ErrorTraveler,
  Traveler as TravelerType,
  WhoAndHowPaperworkOptions,
  WhoAndHowPaperworkValues,
} from './who-and-how-paperwork.types';
import { checkIsLatin } from './utils/check-is-latin';
import type { SmartComponentProps } from '@smart-components/shared/types';
import { getDefaultValues } from './utils/get-default-values';

const Plus = lazy(() => import('@pulse-web-ui/icons/lib/lazy/plus'));

export enum WhoAndHowPaperworkAnalyticEvent {
  ON_TRAVELER_ADD,
  ON_TRAVELER_REMOVE,
}

const WhoAndHowPaperwork: FC<
  SmartComponentProps<
    WhoAndHowPaperworkValues,
    WhoAndHowPaperworkOptions,
    WhoAndHowPaperworkAnalyticEvent
  >
> = memo(
  forwardRef(
    (
      {
        isSubmitting,
        onChange,
        value,
        disabled,
        options: {
          agesLength,
          isMe,
          maxObjectsNumber,
          ageRange,
          hasAuthorized,
          dateStart,
          onDataEntered,
          onDeleteMyself,
          onSyncAges,
        },
        onAnalyticEventSend,
      },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();
      const [travelersCount, setTravelersCount] = useState(agesLength);

      const {
        control,
        trigger,
        getValues,
        setValue,
        formState: { isValid },
      } = useForm<WhoAndHowPaperworkValues>({
        values: getDefaultValues(value, travelersCount),
        resolver: yupResolver(schema),
        context: { ageRange, dateStart },
      });

      const { fields, append, remove } = useFieldArray({
        control,
        name: 'travelers',
      });

      const fieldsLength = fields.length;

      const handleBlur = () => {
        const values = getValues();
        onChange(values);
      };

      useEffect(() => {
        setValue('isValid', isValid);
        onChange(getValues());
      }, [isValid]);

      useEffect(() => {
        if (isSubmitting && hasAuthorized) {
          trigger();
        }
      }, [isSubmitting, hasAuthorized]);

      useLayoutEffect(() => {
        return () => {
          const values = getValues();
          onSyncAges(values.travelers);
        };
      }, []);

      const addTraveler = () => {
        setTravelersCount((prev) => ++prev);
        append({ ...DEFAULT_TRAVELER, id: uuid() });

        const values = getValues();
        onChange(values);
        onAnalyticEventSend &&
          onAnalyticEventSend(WhoAndHowPaperworkAnalyticEvent.ON_TRAVELER_ADD);
      };

      const handleChangeInsurane =
        (onChange: (data: TravelerType) => void) => (data: TravelerType) => {
          const isLatin = checkIsLatin(data);
          isLatin && onChange(data);
        };

      const removeTraveler = (index: number) => () => {
        const isDeletingMyself = isMe && index === 0;
        setTravelersCount((prev) => --prev);
        remove(index);

        const values = getValues();

        onChange(values);
        if (isDeletingMyself) onDeleteMyself();
        onAnalyticEventSend &&
          onAnalyticEventSend(
            WhoAndHowPaperworkAnalyticEvent.ON_TRAVELER_REMOVE
          );
      };

      const maxBirthday = dateStart ? new Date(dateStart) : new Date();

      // Рассчитываем минимальные и максимальные допустимые даты рождения
      const maxDateOfBirth = new Date(
        maxBirthday.getFullYear() - ageRange.min,
        maxBirthday.getMonth(),
        maxBirthday.getDate() - 1
      );

      const minDateOfBirth = new Date(
        maxBirthday.getFullYear() - ageRange.max - 1,
        maxBirthday.getMonth(),
        maxBirthday.getDate() + 1
      );

      if (!hasAuthorized) {
        return null;
      }

      return (
        <Container ref={forwardRef} data-test="Who-and-how-paperwork-block">
          <Form>
            <HeaderWithSubText
              title={t('SMART:WhoAndHowPaperwork.title', {
                defaultValue: i18nDefaultValues.WhoAndHowPaperwork.title,
              })}
              subTitle={t('SMART:WhoAndHowPaperwork.subTitle', {
                defaultValue: i18nDefaultValues.WhoAndHowPaperwork.subTitle,
              })}
            />
            {fields.map((field, index) => (
              <Controller
                key={field.id}
                name={`travelers.${index}`}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Traveler
                    serialNo={index + 1}
                    remove={removeTraveler(index)}
                    value={value}
                    onChange={handleChangeInsurane(onChange)}
                    onBlur={handleBlur}
                    trigger={trigger}
                    error={error as ErrorTraveler | undefined}
                    name={`travelers.${index}`}
                    fieldsLength={fieldsLength}
                    disabled={disabled}
                    minBirthday={minDateOfBirth}
                    maxBirthday={maxDateOfBirth}
                    isMe={Boolean(isMe)}
                    onDataEntered={onDataEntered}
                  />
                )}
              />
            ))}
            {fieldsLength < (maxObjectsNumber || 0) && (
              <Button
                icon={
                  <Suspense>
                    <Plus color="active" width={24} />
                  </Suspense>
                }
                variant="text"
                onClick={addTraveler}
                disabled={disabled}
              >
                {t('SMART:WhoAndHowPaperwork.labels.addMore', {
                  defaultValue:
                    i18nDefaultValues.WhoAndHowPaperwork.labels.addMore,
                })}
              </Button>
            )}
          </Form>
        </Container>
      );
    }
  )
);

export default WhoAndHowPaperwork;
