import { useMemo } from 'react';
import { DriverOptions } from '../types';
import { i18nDefaultValues } from '../i18n';
import { useTranslation } from 'react-i18next';
import { Descriptions } from '@pulse-web-ui/descriptions';

export const useGetDriverDescriptionItems = (drivers: DriverOptions[]) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      drivers.map((driver, index) => {
        {
          return {
            key: `driver-${index}`,
            description: (
              <Descriptions
                title={t('SMART:CheckPolicyAdapter.headers.driver', {
                  defaultValue:
                    i18nDefaultValues.CheckPolicyAdapter.headers.driver,
                  number: `${index + 1}`,
                })}
                columnsCount={2}
                items={[
                  {
                    key: 1,
                    subTitle: `${driver.surname} ${driver.name} ${driver.middlename}`,
                    description: <>{driver.birthday}</>,
                  },
                  {
                    key: 2,
                    subTitle: t(
                      'SMART:CheckPolicyAdapter.headers.driverLicense',
                      {
                        defaultValue:
                          i18nDefaultValues.CheckPolicyAdapter.headers
                            .driverLicense,
                      }
                    ),
                    description: (
                      <>
                        {driver.license}
                        <br />
                        {t('SMART:CheckPolicyAdapter.labels.experience', {
                          defaultValue:
                            i18nDefaultValues.CheckPolicyAdapter.labels
                              .experience,
                          yearOfStart: driver.yearOfStart,
                        })}
                      </>
                    ),
                  },
                ]}
              />
            ),
          };
        }
      }),
    [drivers]
  );
};
