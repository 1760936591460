/* eslint-disable indent */
import { getFormattedDate, getPassportValues } from '@shared/utils';

import type { RegistrationRequestDataType } from '@features/auth-box';
import { AddressType } from '@shared/constants';

type GetRegistrationDataFn = (
  formState: any,
  regUtm: Record<string, any> | undefined,
  promoAgreement: boolean
) => RegistrationRequestDataType;

export const getRegistrationData: GetRegistrationDataFn = (
  formState,
  regUtm,
  promoAgreement
) => {
  const extendedIntermediaryChannelCode = `${regUtm?.media_source ?? '0000'},${
    regUtm?.campaign ?? 'WEB'
  }`;

  const userPolicies = [
    { approvedAt: new Date(), code: '003' },
    { approvedAt: new Date(), code: '005' },
    ...(promoAgreement ? [{ approvedAt: new Date(), code: '002' }] : []),
  ];

  const registrationData: RegistrationRequestDataType = {
    user: {
      userPolicies,
      extendedIntermediaryChannelCode,
      firstName: formState?.WhoIsPolicyholder?.name?.trim(),
      lastName: formState?.WhoIsPolicyholder?.surname?.trim(),
      middleName: formState?.WhoIsPolicyholder?.middlename?.trim() || '',
      birthDay: formState?.WhoIsPolicyholder?.birthday
        ? getFormattedDate(formState?.WhoIsPolicyholder?.birthday)
        : '',
      passport: {
        // TODO: сделать через константу
        cardType: '12',
        cardSeries: formState?.WhoIsPolicyholder?.passport
          ? getPassportValues(formState?.WhoIsPolicyholder?.passport)[0]
          : '',
        cardNumber: formState?.WhoIsPolicyholder?.passport
          ? getPassportValues(formState?.WhoIsPolicyholder?.passport)[1]
          : '',
        cardIssueDate: formState?.WhoIsPolicyholder?.passportDate
          ? getFormattedDate(formState?.WhoIsPolicyholder?.passportDate)
          : '',
        cardIssuerCode: formState?.WhoIsPolicyholder?.passportCode
          ? formState?.WhoIsPolicyholder?.passportCode
              .trim()
              .split('-')
              .join('')
          : '',
        cardIssuerName:
          formState?.WhoIsPolicyholder?.passportByWho?.trim() || '',
        addresses: [
          {
            addressType: AddressType.REGISTRATION,
            address: formState?.WhoIsPolicyholder?.address?.value?.trim() || '',
            addressCode: formState?.WhoIsPolicyholder?.address?.fias_id || '',
          },
        ],
      },
      email: formState?.WhoIsPolicyholder?.email,
      clientCategory: 'STANDART',
      utmSource: regUtm?.utm_source || '',
      utmMedium: regUtm?.utm_medium || '',
      utmCampaign: regUtm?.utm_campaign || '',
      utmContent: regUtm?.utm_content || '',
      wmId: regUtm?.wm_id || '',
    },
  };

  return registrationData;
};
