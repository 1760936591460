import { DaDataAddress, DaDataSuggestion } from '@pulse-web-ui/dadata';
import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

export interface GetDadataSuggestionsResponse {
  suggestions:
    | DaDataSuggestion<DaDataAddress>
    | DaDataSuggestion<DaDataAddress>[];
}

export interface GetDadataSuggestionsRequestData {
  count: number;
  query: string;
}

export const useGetDadataSuggestions = (
  data: GetDadataSuggestionsRequestData | undefined
) => {
  return useQuery(
    ['get-dadata-suggestions', data],
    fetchData<GetDadataSuggestionsResponse>({
      url: `${Endpoints.DADATA}/suggest/address`,
      method: 'POST',
      data,
    }),
    { enabled: false }
  );
};
