import { useEffect } from 'react';
import { useStores } from '@shared/hooks';

import { useGetRisks } from './use-get-risks';
import type { FormBuilderUpdateHandler } from '@features/form-builder';

export const useHandleGetRisks = (
  updateFormValue: FormBuilderUpdateHandler
) => {
  //  TODO: доработать, когда будет известно, нужны ли риски в в initState
  const {
    MainStore: {
      // initProductStore: { initState },
      applicationStore: { activeStep },
    },
  } = useStores();

  // useEffect(() => {
  //   if (initState?.code) {
  //     refetch();
  //   }
  // }, [initState?.code]);

  const enabled = activeStep === 3;
  const { data, isLoading, refetch } = useGetRisks(enabled);

  useEffect(() => {
    if (!isLoading && data) {
      // setInitState({ ...initState, ...data });
      updateFormValue('InsuranceRisks', {
        insuranceRisks: data.risks,
      });
    }
  }, [isLoading, data]);

  return refetch;
};
