import { useStores } from './use-stores';
import { useCallback } from 'react';

export const useHandleManualInput = (carNumber: string | undefined) => {
  const {
    MainStore: {
      initProductStore: {
        initState: { minProductLimit },
      },
      applicationStore: { updateFormValue, setIsManualCarInput },
    },
  } = useStores();

  return useCallback(() => {
    setIsManualCarInput(true);

    updateFormValue('Car', {
      carNumber,
      marketPrice: minProductLimit,
    });
  }, [carNumber, minProductLimit]);
};
