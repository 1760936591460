/* eslint-disable @typescript-eslint/no-explicit-any */
import { boolean, date, object, string } from 'yup';
import i18n from '@app/i18n/config';
import { i18nDefaultValues } from './i18n';
import {
  maxLength,
  onlyCyrillic,
  onlyLetters,
  phoneRegEx,
  emailRegEx,
  PASSPORT_REG_EXP,
  PASSPORT_CODE_REG_EXP,
  maxLength100,
  FiasLevel,
} from '@shared/constants';

import {
  calculateAge,
  checkDateBefore,
  checkDateBetween,
  phoneValueCasting,
} from '@shared/utils';

const errorMessages = i18nDefaultValues.AuthSchema.errors;
const notFilled = i18n.t(
  'SMART:AuthSchema.errors.notFilled',
  errorMessages.notFilled
);
const maxLengthMessage = i18n.t(
  'SMART:AuthSchema.errors.maxLength',
  errorMessages.maxLength
);
const maxLength100Message = i18n.t(
  'SMART:AuthSchema.errors.maxLength100',
  errorMessages.maxLength100
);
const useCyrillic = i18n.t(
  'SMART:AuthSchema.errors.useCyrillic',
  errorMessages.useCyrillic
);
const nameStartWithLetter = i18n.t(
  'SMART:AuthSchema.errors.nameStartWithLetter',
  errorMessages.nameStartWithLetter
);
const lastnameStartWithLetter = i18n.t(
  'SMART:AuthSchema.errors.lastnameStartWithLetter',
  errorMessages.lastnameStartWithLetter
);
const middleNameStartWithLetter = i18n.t(
  'SMART:AuthSchema.errors.middleNameStartWithLetter',
  errorMessages.middleNameStartWithLetter
);
const enterEmail = i18n.t(
  'SMART:AuthSchema.errors.enterEmail',
  errorMessages.enterEmail
);
const enterPhone = i18n.t(
  'SMART:AuthSchema.errors.enterPhone',
  errorMessages.enterPhone
);
const incorrectEmail = i18n.t(
  'SMART:AuthSchema.errors.incorrectEmail',
  errorMessages.incorrectEmail
);
const wrongPhone = i18n.t(
  'SMART:AuthSchema.errors.wrongPhone',
  errorMessages.wrongPhone
);
const insuranceFromAgeEighteen = i18n.t(
  'SMART:AuthSchema.errors.insuranceFromAgeEighteen',
  errorMessages.insuranceFromAgeEighteen
);
const insuranceUpToAgeHundred = i18n.t(
  'SMART:AuthSchema.errors.insuranceUpToAgeHundred',
  errorMessages.insuranceUpToAgeHundred
);
const passportFromYear1997 = i18n.t(
  'SMART:AuthSchema.errors.passportFromYear1997',
  errorMessages.passportFromYear1997
);
const passportFromAgeFourteen = i18n.t(
  'SMART:AuthSchema.errors.passportFromAgeFourteen',
  errorMessages.passportFromAgeFourteen
);
const invalidPassportFormat = i18n.t(
  'SMART:AuthSchema.errors.invalidPassportFormat',
  errorMessages.invalidPassportFormat
);
const invalidPassportCodeFormat = i18n.t(
  'SMART:AuthSchema.errors.invalidPassportCodeFormat',
  errorMessages.invalidPassportCodeFormat
);
const numberMatchesAgent = i18n.t(
  'SMART:AuthSchema.errors.numberMatchesAgent',
  errorMessages.numberMatchesAgent
);
const addressIsNotDefine = i18n.t(
  'SMART:AuthSchema.errors.addressIsNotDefine',
  errorMessages.addressIsNotDefine
);

const defaultFioSchema = string()
  .required(notFilled)
  .test('maxLength', maxLengthMessage, (value) => maxLength.test(value!))
  .test('onlyCyrillic', useCyrillic, (value) => onlyCyrillic.test(value!));

export const profileFormSchema = object().shape(
  {
    name: defaultFioSchema.test('onlyLetters', nameStartWithLetter, (value) =>
      onlyLetters.test(value!)
    ),
    surname: defaultFioSchema.test(
      'onlyLetters',
      lastnameStartWithLetter,
      (value) => onlyLetters.test(value!)
    ),
    middlename: string()
      .notRequired()
      .when({
        is: (value: string) => value?.length,
        then: () =>
          defaultFioSchema
            .test('onlyLetters', middleNameStartWithLetter, (value) =>
              onlyLetters.test(value!)
            )
            .test('onlyCyrillic', useCyrillic, (value) =>
              onlyCyrillic.test(value!)
            ),
      }),
    birthday: date()
      .required(notFilled)
      .nullable()
      .test('is-date', notFilled, (value) => value instanceof Date)
      .test(
        'isAdult',
        insuranceFromAgeEighteen,
        (value) => calculateAge(new Date(value!)) >= 18
      )
      .test(
        'tooOld',
        insuranceUpToAgeHundred,
        (value) => calculateAge(new Date(value!)) < 100
      ),
    passport: string()
      .required(notFilled)
      .test('invalidPassport', invalidPassportFormat, (value) =>
        PASSPORT_REG_EXP.test(value!)
      ),
    passportDate: date()
      .required(notFilled)
      .nullable()
      .test('is-date', notFilled, (value) => value instanceof Date)
      .test('notEdult', passportFromYear1997, (value) => {
        if (value) return checkDateBefore(new Date('01.01.1997'), value);
      })
      .test('notFourteen', passportFromAgeFourteen, function (value) {
        const { birthday } = this.parent;
        if (!birthday || !value) {
          return true;
        }
        const minDate = new Date(birthday);
        minDate.setFullYear(minDate.getFullYear() + 14);
        return checkDateBetween(new Date(value), minDate, new Date());
      }),
    passportByWho: string()
      .required(notFilled)
      .test('onlyCyrillic', useCyrillic, (value) => onlyCyrillic.test(value!))
      .test('maxLength', maxLength100Message, (value) =>
        maxLength100.test(value!)
      ),
    passportCode: string()
      .required(notFilled)
      .test('invalidPassportCode', invalidPassportCodeFormat, (value) =>
        PASSPORT_CODE_REG_EXP.test(value!)
      ),
    address: object({
      value: string().optional(),
      fias_id: string().optional(),
      fias_level: string().optional(),
    })
      .required(notFilled)
      .test(
        'addressIsNotDefine',
        addressIsNotDefine,
        (value) =>
          !!value.fias_id &&
          (value.fias_level === FiasLevel.HOUSE ||
            value.fias_level === FiasLevel.APARTMENT)
      ),
  },
  [['middlename', 'middlename']]
);

export const getPhoneValidationSchema = (agentLogin?: string) =>
  string()
    .required(enterPhone)
    .test('validPhone', wrongPhone, (value) => phoneRegEx.test(value as string))
    .test('isAgentPhone', numberMatchesAgent, (value) =>
      agentLogin ? phoneValueCasting(value) !== agentLogin : true
    );

export const getEmailValidationSchema = () =>
  string()
    .required(enterEmail)
    .test(
      'validEmail',
      incorrectEmail,
      (value) => !!value && emailRegEx.test(value)
    )
    .test('maxLength', maxLengthMessage, (value) => maxLength.test(value!));

export const getFormInsuranceContactsSchema = (agentLogin?: string) =>
  profileFormSchema.shape({
    email: getEmailValidationSchema(),
    phone: getPhoneValidationSchema(agentLogin),
    hasAuthorized: boolean(),
    client: boolean(),
    forMe: boolean(),
    isValid: boolean(),
  });
