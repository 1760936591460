/* eslint-disable indent */
import type { DocumentStatusType } from '@pulse-web-ui/documents-list';
import { downloadBlob, openPdf } from '@shared/utils';
import type {
  OnDocumentLoadHandler,
  GetHandledResponse,
  GetQueryEnabled,
  GetStatusList,
  SetQueryEnabled,
  GetQueryKey,
  RemoveQuery,
} from './use-handle-download-pdf.types';
import { DOCUMENT_QUERY_KEY } from '@shared/constants';

// Инициируем начальные значения на основе количества документов.
export const initQueryEnabledList = (length: number = 0): boolean[] =>
  Array(length).fill(false);

export const initDocumentsStatusList = (
  length: number = 0
): DocumentStatusType[] =>
  Array(length).fill({ isError: false, isLoading: false });

export const getHandledResponse: GetHandledResponse =
  ({
    documentsList,
    documentsStatusList,
    queryEnabledList,
    removeQuery,
    isIOS,
  }) =>
  ({ data, isError, isLoading, status }, index) => {
    const result = { isError, isLoading };
    const isSameLoading = isLoading === documentsStatusList[index]?.isLoading;
    const isSameError = isError === documentsStatusList[index]?.isError;
    const isQueryEnabledAndLoading =
      queryEnabledList?.[index] && documentsStatusList[index]?.isLoading;

    // Для запроса с ошибкой снимаем признак скачивания, чтобы сбросить состояние.
    if (!documentsList?.length) return { ...result, isEnabled: false };

    // Для запроса с ошибкой снимаем признак скачивания, чтобы сбросить состояние.
    if (status === 'error' && !isSameError) {
      removeQuery(documentsList[index].clientDocument);
      return { isError, isLoading, isEnabled: false, isModified: true };
    }

    // Сохраняем файл у пользователя.
    if (status === 'success' && data && isQueryEnabledAndLoading) {
      onDocumentLoadHandler({
        data,
        name: documentsList[index].clientDocument,
        isIOS,
        onRemove: removeQuery,
      });
      return { ...result, isEnabled: false, isModified: true };
    }

    // Обновление статуса загрузки
    if (!isSameLoading)
      return {
        ...result,
        isEnabled: queryEnabledList[index],
        isModified: true,
      };

    // Для остальных кейсов обновляем статусы без ререндера.
    return {
      isError,
      isLoading,
      isEnabled: queryEnabledList[index],
    };
  };

export const getQueryEnabled: GetQueryEnabled = (resultData) =>
  resultData.map((item) => item.isEnabled);

export const setQueryEnabled: SetQueryEnabled = (actualList, targetIndex) =>
  actualList.map((enabled, index) => (index === targetIndex ? true : enabled));

export const getStatusList: GetStatusList = (resultData) =>
  resultData.map(({ isError, isLoading }) => ({ isError, isLoading }));

export const getQueryKey: GetQueryKey = (name) =>
  `${DOCUMENT_QUERY_KEY}-${name}`;

export const removeQuery: RemoveQuery = (queryClient) => (name) => {
  queryClient.removeQueries({
    queryKey: [getQueryKey(name)],
  });
};

const handleDownloadBlob = (data: string, fileName: string) => {
  const blob = new Blob([data], {
    type: 'application/pdf',
  });

  downloadBlob(blob, fileName);
};

const onDocumentLoadHandler: OnDocumentLoadHandler = ({
  data,
  name,
  isIOS,
  onRemove,
}) => {
  const fileName = `${name}.pdf`;

  if (!isIOS) {
    openPdf(data.data, fileName);
  } else {
    handleDownloadBlob(data.data, fileName);
  }

  // Стираем кэш запроса для обновлённой загрузки.
  onRemove(name); // TODO: удалить после настройки кэширования
};
