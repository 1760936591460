/* eslint-disable indent */
import { useEffect } from 'react';
import { AxiosError } from 'axios';

import {
  Endpoints,
  QUERY_KEYS,
  REGISTRATION,
  REGISTRATION_ERROR_TEXT,
} from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { RegistrationRequestDataType } from '../auth-box.type';

import type { AuthenticationResponseType } from '../auth-box.type';
import { ErrorCode, hasError } from '@shared/utils';

export const useHandleRegistration = (
  registrationData: RegistrationRequestDataType,
  updateFormValue: (fieldName: string, value: any) => void,
  setFormValueError: (fieldName: string, error: any) => void,
  resAuthentication?: AuthenticationResponseType
) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: {
        callOtp,
        authTokens,
        accessToken,
        handleOtp,
        handleAuth,
        setUseOtp,
        setAuthTokens,
        setDisableForm,
        setExternalAuthPhone,
      },
    },
  } = useStores();

  // TODO: Добавить возвращаемый тип
  const { isLoading, error, res, refetch, remove } = useRequest(
    QUERY_KEYS.registration,
    'post',
    Endpoints.REG,
    registrationData,
    [
      callOtp,
      resAuthentication?.confirmationId,
      resAuthentication?.authenticationToken,
    ],
    true,
    accessToken
  );

  useEffect(() => {
    if (!isLoading && res && authTokens) {
      setDisableForm(false);
      setErrorRetry(false);

      const updatedAuthTokens = { ...authTokens };
      const csrfToken = res?.authorization?.csrfToken;

      updatedAuthTokens.authorization!.accessToken =
        res?.authorization?.accessToken;
      updatedAuthTokens.authorization!.isVip = res.isVip;
      updatedAuthTokens.authorization!.csrfToken = csrfToken;

      setAuthTokens(updatedAuthTokens);

      localStorage.removeItem('csrfToken');

      if (csrfToken) {
        localStorage.setItem('csrfToken', csrfToken);
      }
    }

    if (!isLoading && error) {
      setAuthTokens(undefined);
      setDisableForm(false);
      handleOtp('', false);
      setUseOtp(true);
      setExternalAuthPhone(undefined);
      handleAuth('');

      if (hasError(error, ErrorCode.ValidationError)) {
        const regDescription = (error as AxiosError<any>).response?.data
          ?.description;

        const regFields = Object.values(REGISTRATION);
        const regKeys = Object.keys(REGISTRATION);

        if (regDescription) {
          regKeys.forEach((field, index) => {
            if (regDescription?.includes(field)) {
              setFormValueError(
                `WhoIsPolicyholder.${regFields[index]}`,
                REGISTRATION_ERROR_TEXT[
                  field as keyof typeof REGISTRATION_ERROR_TEXT
                ]
              );
            }
          });
        }

        updateFormValue('WhoIsPolicyholder.client', false);
        remove();
      }
    }
  }, [isLoading, error, res]);

  return {
    isLoadingRegistration: isLoading,
    errorRegistration: error,
    resRegistration: res,
    refetchRegistration: refetch,
    removeRegistration: remove,
  };
};
