import { StyledThemeProvider, baseTheme } from '@pulse-web-ui/theme';
import React, {
  FC,
  memo,
  forwardRef,
  Ref,
  useEffect,
  useCallback,
} from 'react';
import { WidgetContainerStyled } from './insurance-risks.styles';
import { i18nDefaultValues } from './i18n';
import { useTranslation } from 'react-i18next';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import { SmartComponentBaseProps } from '@smart-components/shared/types';
import { InsuranceRisksValues } from './insurance-risks.types';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectorCard } from '@pulse-web-ui/selector-card';
import { RisksType } from '@shared/types';

export const InsuranceRisks: FC<SmartComponentBaseProps<InsuranceRisksValues>> =
  memo(
    forwardRef(
      ({ value, onChange, isSubmitting }, forwardRef: Ref<HTMLDivElement>) => {
        const { t } = useTranslation();

        const {
          trigger,
          watch,
          control,
          setValue,
          formState: { isValid },
        } = useForm<InsuranceRisksValues>({
          defaultValues: value,
          resolver: yupResolver(schema),
          mode: 'all',
        });

        const { fields } = useFieldArray({
          control,
          name: 'insuranceRisks',
        });

        useEffect(() => {
          const subscription = watch((values) => {
            onChange(values);
          });

          return () => subscription.unsubscribe();
        }, [watch]);

        useEffect(() => {
          if (isSubmitting) {
            trigger();
          }
        }, [isSubmitting]);

        useEffect(() => {
          setValue('isValid', isValid);
        }, [isValid]);

        const handleClick = useCallback(
          (value: RisksType, onChange: (item: RisksType) => void) => () => {
            onChange({
              ...value,
              checked: !value.checked,
            });
          },
          [value, onChange]
        );

        return (
          <WidgetContainerStyled ref={forwardRef}>
            <HeaderWithSubText
              title={t('SMART:InsuranceRisks.title', {
                defaultValue: i18nDefaultValues.InsuranceRisks.title,
              })}
            />
            <StyledThemeProvider theme={baseTheme}>
              {fields.map((field, index) => (
                <Controller
                  key={field.id}
                  name={`insuranceRisks.${index}`}
                  control={control}
                  render={({ field }) => (
                    <SelectorCard
                      name={field.value.name}
                      key={`${field.value.code}`}
                      id={field.value.code}
                      label={field.value.name}
                      checked={field.value.checked}
                      description={field.value.description}
                      stopContentPropagation={field.value.checked}
                      readOnly={field.value.binding}
                      onClick={handleClick(field.value, field.onChange)}
                      mobileFullWidth
                    />
                  )}
                />
              ))}
            </StyledThemeProvider>
          </WidgetContainerStyled>
        );
      }
    )
  );
