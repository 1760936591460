import { Car } from '@entities/import-smart-components/car';
import { FallbackSkeleton } from '@shared/components';
import { useStores, useUpdateEffect } from '@shared/hooks';
import {
  useGetBrands,
  useGetModels,
  useGetYears,
  useGetPowers,
  useHandleCarNumber,
  useGetCarPrice,
} from './queries';
import { observer } from 'mobx-react-lite';
import {
  type Ref,
  Suspense,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SmartComponentAdapterProps } from '@smart-components/shared/types';
import { VehicleData } from '@shared/types';
import type { CarValues } from '@smart-components/car/types';
import { CarData } from '@smart-components/car';

const getOptionsFromVehicleData = (vehicleData: VehicleData[]) =>
  vehicleData.map(({ code, name }) => ({
    label: name,
    value: code,
  }));

export const CarAdapter = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting }: SmartComponentAdapterProps<CarValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: {
            isManualCarInput,
            isCarNumberSmartComponentShowing,
            updateFormValue,
            hasUnknownCarNumberError,
            setHasUnknownCarNumberError,
          },
          initProductStore: {
            initState: { code, minProductLimit, maxProductLimit, extraParams },
          },
          productStore: {
            car,
            formState: { Car: CarFormState },
            setCar,
          },
        },
      } = useStores();

      //TODO: вынести в regex после переноса useHandleCarNumber из BFFBox
      const carNumberWithoutMask = CarFormState?.carNumber?.replaceAll(
        /[_\s]/g,
        ''
      );

      const [brandId, setBrandId] = useState<string | undefined>(
        CarFormState?.car?.brand?.value
      );
      const [modelId, setModelId] = useState<string | undefined>(
        CarFormState?.car?.model?.value
      );
      const [yearId, setYearId] = useState<string | undefined>(
        CarFormState?.car?.manufactureYear?.value
      );
      const [powerId, setPowerId] = useState<string | undefined>(
        CarFormState?.car?.power?.value
      );

      const region = useMemo(
        () => extraParams?.find(({ code }) => code === 'defaultRegion')?.value,
        [extraParams]
      );

      const { refetch: refetchCar, data: carData } = useHandleCarNumber(
        code,
        carNumberWithoutMask
      );
      const { refetch: refetchBrands, data: brandsData } = useGetBrands(code);
      const { data: modelsData } = useGetModels({ code, brandId });
      const { data: yearsData } = useGetYears({ code, brandId, modelId });
      const { data: powersData } = useGetPowers({
        code,
        brandId,
        modelId,
        yearId,
      });

      const { refetch: refetchCarPrice, data: carPriceResponse } =
        useGetCarPrice({
          code,
          brandId,
          modelId,
          yearId,
          powerId,
          region,
        });

      const handleFindCar = useCallback(() => {
        if (code && carNumberWithoutMask) refetchCar();
      }, [code, carNumberWithoutMask]);

      const handleFindBrands = useCallback(() => {
        if (!code) return;
        refetchBrands();
      }, [code, refetchBrands]);

      const handleFindModels = useCallback((brand: string) => {
        setBrandId(brand);
        // Отчистка идентификаторов которые зависят от id бренда
        setModelId(undefined);
        setYearId(undefined);
        setPowerId(undefined);
      }, []);

      const handleFindYears = useCallback((model: string) => {
        setModelId(model);
        // Отчистка идентификаторов которые зависят от id модели
        setYearId(undefined);
        setPowerId(undefined);
      }, []);

      const handleFindPowers = useCallback((year: string) => {
        setYearId(year);
        // Отчистка идентификаторов которые зависят от id года
        setPowerId(undefined);
      }, []);

      const handleFindCarPrice = useCallback((data: CarData) => {
        setBrandId(data.brand?.value);
        setModelId(data.model?.value);
        setYearId(data.manufactureYear?.value);
        setPowerId(data.power?.value);
      }, []);

      const brands = useMemo(
        () =>
          brandsData?.data ? getOptionsFromVehicleData(brandsData.data) : [],
        [brandsData?.data]
      );

      const models = useMemo(
        () =>
          modelsData?.data ? getOptionsFromVehicleData(modelsData.data) : [],
        [modelsData?.data]
      );

      const years = useMemo(
        () =>
          yearsData?.data ? getOptionsFromVehicleData(yearsData.data) : [],
        [yearsData?.data]
      );

      const powers = useMemo(
        () =>
          powersData?.data ? getOptionsFromVehicleData(powersData.data) : [],
        [powersData?.data]
      );

      const options = useMemo(
        () => ({
          onFindCar: handleFindCar,
          onFindBrands: handleFindBrands,
          onFindModels: handleFindModels,
          onFindYears: handleFindYears,
          onFindPowers: handleFindPowers,
          onFindCarPrice: handleFindCarPrice,
          brands,
          models,
          years,
          powers,
          minProductLimit: Number(minProductLimit),
          maxProductLimit: Number(maxProductLimit),
          car,
          isManualCarInput,
          hasUnknownCarNumberError,
          setHasUnknownCarNumberError,
        }),
        [
          isCarNumberSmartComponentShowing,
          handleFindBrands,
          handleFindModels,
          handleFindYears,
          handleFindCar,
          handleFindPowers,
          handleFindCarPrice,
          brands,
          models,
          years,
          powers,
          minProductLimit,
          maxProductLimit,
          car,
          isManualCarInput,
          hasUnknownCarNumberError,
          setHasUnknownCarNumberError,
        ]
      );

      useUpdateEffect(() => {
        if (code && brandId && modelId && yearId && powerId && region)
          refetchCarPrice();
      }, [powerId]);

      useEffect(() => {
        if (!carData) return;

        setCar(carData);

        updateFormValue('Car', {
          ...CarFormState,
          car: {
            model: { label: carData?.model, value: carData?.modelId },
            power: {
              label: carData?.power?.toString(),
              value: carData?.power?.toString(),
            },
            brand: { label: carData?.make, value: carData?.makeId },
            manufactureYear: {
              label: carData?.year?.toString(),
              value: carData?.year?.toString(),
            },
          },
          isManualVINInput: false,
          carNumber: CarFormState?.carNumber,
          vin: carData.vin,
          marketPrice: carData?.price?.price ?? minProductLimit,
          mileage: null,
        });

        updateFormValue('Drivers', {
          minDriverAge: null,
          minDriverExperience: null,
        });
      }, [carData]);

      useUpdateEffect(() => {
        if (!carPriceResponse) return;

        setCar({ ...car, price: carPriceResponse });

        updateFormValue('Car', {
          ...CarFormState,
          marketPrice: carPriceResponse?.price ?? minProductLimit,
        });
      }, [carPriceResponse]);

      if (isCarNumberSmartComponentShowing) return;

      return (
        <Suspense fallback={<FallbackSkeleton height={274} />}>
          <Car
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
          />
        </Suspense>
      );
    }
  )
);
