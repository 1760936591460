import { WhoIsPolicyholderData } from '@features/auth-box/auth-box.type';
import { useEffect, useState } from 'react';

import { AddressType, Endpoints, QUERY_KEYS } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import type { WhoIsPolicyholderValues } from '@smart-components/who-is-policyholder';
import { UpdateProfileResponse } from '@shared/queries/application/update-profile/use-update-profile.types';
import {
  GetDadataSuggestionsRequestData,
  useGetDadataSuggestions,
} from '@shared/queries';
import { getFormattedPassport } from '@shared/utils';

export const useHandleGetProfile = (
  whoIsPolicyholderData: WhoIsPolicyholderData,
  updateFormValue: (fieldName: string, value: any) => void,
  // TODO: Убрать any когда у refetchRegistration появится возвращаемый тип
  refetchRegistration: () => Promise<any>,
  removeConfirmAuthentication: () => void
) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: {
        handleAuth,
        handleOtp,
        handleProfile,
        setAuthTokens,
        accessToken,
        setDisableForm,
        setHasAuthorized,
        hasAuthorized,
        setRefetchAuthentication,
        handleUpdateDisableProfileState,
        setUseOtp,
        profile: storeProfile,
        setIsUserNotDefine,
        isFastAuthOpen,
      },
      productStore: {
        isPolicyBanned,
        formState: { WhoIsPolicyholder },
      },
      applicationStore: { setIsRegistration },
    },
  } = useStores();

  const { isLoading, error, res, refetch, remove } =
    useRequest<UpdateProfileResponse>(
      QUERY_KEYS.getProfile,
      'post',
      Endpoints.GET_PROFILE,
      {},
      [],
      true,
      accessToken
    );

  const [dadataRequest, setDadataRequest] =
    useState<GetDadataSuggestionsRequestData>();

  const {
    refetch: refetchDadataSuggestions,
    data: dadataSuggestions,
    isLoading: isDadataSuggestionsLoading,
  } = useGetDadataSuggestions(dadataRequest);

  useEffect(() => {
    if (!isLoading && res) {
      const address = res?.profile?.passport?.addresses.find(
        ({ addressType }) => addressType === AddressType.REGISTRATION
      )?.address;

      if (address) {
        setDadataRequest({
          count: 10,
          query: address,
        });
      }
    }
  }, [res, isLoading]);

  useEffect(() => {
    if (dadataRequest) {
      refetchDadataSuggestions();
    }
  }, [dadataRequest]);

  useEffect(() => {
    if (!isDadataSuggestionsLoading) {
      const dadataValue = Array.isArray(dadataSuggestions?.suggestions)
        ? dadataSuggestions?.suggestions?.[0]
        : dadataSuggestions?.suggestions;

      if (!!dadataValue) {
        updateFormValue('WhoIsPolicyholder', {
          ...WhoIsPolicyholder,
          address: {
            value: dadataValue.value,
            fias_id: dadataValue.data.fias_id,
            fias_level: dadataValue.data.fias_level,
          },
        });
      }
    }
  }, [dadataSuggestions, isDadataSuggestionsLoading]);

  useEffect(() => {
    if (!isLoading && res) {
      setErrorRetry(false);

      const { profile, lockedFields } = res;

      const {
        cardSeries,
        cardNumber,
        cardIssuerName,
        cardIssuerCode,
        cardIssueDate,
        addresses,
      } = profile?.passport ?? {};

      const registrationAddress = addresses?.find(
        ({ addressType }) => addressType === AddressType.REGISTRATION
      );

      const newValue: Partial<WhoIsPolicyholderValues> = {
        name: profile?.firstName,
        surname: profile?.lastName,
        middlename: profile?.middleName,
        birthday: profile?.birthDate ? new Date(profile?.birthDate) : undefined,
        phone: profile?.phone.slice(2),
        email: profile?.email || '',
        forMe: whoIsPolicyholderData?.forMe || profileDataIsFulfilled(profile),
        hasAuthorized: hasAuthorized,
        isValid: !!profile?.email && !!profile.birthDate,
        passport:
          cardSeries && cardNumber
            ? getFormattedPassport(`${cardSeries}${cardNumber}`)
            : undefined,
        passportByWho: cardIssuerName,
        passportCode: cardIssuerCode,
        passportDate: cardIssueDate ? new Date(cardIssueDate) : undefined,
        address: {
          value: registrationAddress?.address,
          fias_id: registrationAddress?.addressCode,
        },
      };

      handleProfile(res);
      handleUpdateDisableProfileState({
        name: !!profile?.firstName,
        surname: !!profile?.lastName,
        secondName: lockedFields?.middleName || !!profile?.middleName,
        birthDate: !!profile?.birthDate,
      });
      updateFormValue('WhoIsPolicyholder', newValue);
      setDisableForm(false);
    }

    if (
      !isLoading &&
      hasAuthorized &&
      error &&
      error?.response?.status === 403 &&
      error?.response?.data?.code === 'INVALID_ROLE'
    ) {
      localStorage.removeItem('csrfToken');

      if (isFastAuthOpen) {
        setHasAuthorized(false);
        setAuthTokens(undefined);
        removeConfirmAuthentication();
        setUseOtp(true);
        handleOtp('', false);
        handleAuth('');
        setRefetchAuthentication(true);

        setIsUserNotDefine(true);
      } else {
        setDisableForm(true);
        refetchRegistration().then(() => {
          setIsRegistration(true);
        });
      }
    }
  }, [isLoading, error, res, hasAuthorized]);

  useEffect(() => {
    const hasAccessToken = Boolean(accessToken);
    setHasAuthorized(hasAccessToken);

    if (accessToken && !storeProfile) {
      setDisableForm(true);
      refetch();
    }
  }, [accessToken, storeProfile]);

  useEffect(() => {
    if (isPolicyBanned) remove();
  }, [isPolicyBanned]);

  return {
    isLoadingGetProfile: isLoading,
    errorGetProfile: error,
    resGetProfile: res,
    refetchGetProfile: refetch,
  };
};

// Оформляю полис на себя включен, если у профайла заполнены все поля для заполнения путешественника.
const profileDataIsFulfilled = (
  profile: UpdateProfileResponse['profile']
): boolean => !!profile.firstName && !!profile.lastName && !!profile.birthDate;
