import { format } from 'date-fns';
import type { VehicleOwner } from '../types';
import type { CarOwnerValues } from '@smart-components/car-owner';
import { parsingDocument } from './parsing-document';
import { DATE_FORMAT, PASSPORT_REG_EXP } from '@shared/constants';
import type { PolicyholderFormValues } from '@smart-components/policyholder-form';

export const getVehicleOwner = (
  forMe: boolean,
  carOwner?: CarOwnerValues,
  PolicyholderForm?: PolicyholderFormValues
): VehicleOwner => {
  const passport = carOwner?.passport || PolicyholderForm?.passport || '';
  const [series, number] = parsingDocument(passport, PASSPORT_REG_EXP);

  const address = forMe
    ? PolicyholderForm?.address.value
    : carOwner?.address.value;

  const addressCode = forMe
    ? PolicyholderForm?.address.fias_id
    : carOwner?.address.fias_id;

  const lastName = carOwner?.surname || PolicyholderForm?.surname || '';

  const middleName = carOwner?.middlename || PolicyholderForm?.middlename || '';

  const firstName = carOwner?.name || PolicyholderForm?.name || '';

  const date = carOwner?.birthday || PolicyholderForm?.birthday || new Date();
  const birthDate = format(date, DATE_FORMAT);

  const passportDate =
    carOwner?.passportDate || PolicyholderForm?.passportDate || new Date();
  const cardIssueDate = format(passportDate, DATE_FORMAT);

  const cardIssuerCode =
    carOwner?.passportCode || PolicyholderForm?.passportCode || '';

  const cardIssuerName =
    carOwner?.passportByWho || PolicyholderForm?.passportByWho || '';

  return {
    lastName,
    firstName,
    middleName,
    birthDate,
    passport: {
      cardSeries: series.replace(' ', ''),
      cardNumber: number.trim(),
      cardIssueDate,
      cardIssuerCode,
      cardIssuerName,
    },
    address: {
      address: address ?? '',
      addressCode: addressCode ?? '',
    },
  };
};
