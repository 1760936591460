import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';
import type { SubmitRequest, SubmitResponse } from '../../types';
import { requestBody } from '../../schemas';

export const useHandleSubmit = (data: SubmitRequest) => {
  const enabled = requestBody.isValidSync(data);
  requestBody
    .validate(data)
    .then((res) => console.log('success', res))
    .catch((err) => console.log('error', err));

  return useQuery(
    ['submit', data],
    fetchData<SubmitResponse>({
      url: Endpoints.SUBMIT_ORDER,
      method: 'POST',
      data,
    }),
    { enabled }
  );
};
