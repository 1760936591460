import { i18nDefaultValues } from './i18n';
import i18n from '@app/i18n/config';

const STS = i18n.t(
  'SMART:VehicleDocumentAdapter.labels.STS',
  i18nDefaultValues.VehicleDocumentAdapter.labels.STS
);
const PTS = i18n.t(
  'SMART:VehicleDocumentAdapter.labels.PTS',
  i18nDefaultValues.VehicleDocumentAdapter.labels.PTS
);
const EPTS = i18n.t(
  'SMART:VehicleDocumentAdapter.labels.EPTS',
  i18nDefaultValues.VehicleDocumentAdapter.labels.EPTS
);

// TODO: ДОбавить костанду Document
export const DOCUMENTS = [
  { value: '31', label: STS },
  { value: '30', label: PTS },
  { value: '41', label: EPTS },
];
