import { makeAutoObservable } from 'mobx';
import type {
  WhoAndHowPaperworkOptions,
  WhoIsPolicyholderOptions,
} from '@smart-components/index';

type TypeSmartComponentAdditionalOptionsStore = {
  WhoAndHowPaperwork?: Partial<WhoAndHowPaperworkOptions>;
  WhoIsPolicyholder?: Partial<WhoIsPolicyholderOptions>;
};

export type SmartComponentStoreKey =
  keyof TypeSmartComponentAdditionalOptionsStore;

export class SmartComponentAdditionalOptionsStore {
  additionalOptions: TypeSmartComponentAdditionalOptionsStore = {}; // Доп параметры для смарт компонентов продукта

  constructor() {
    makeAutoObservable(this);
  }

  setAdditionalOptions = <T extends SmartComponentStoreKey>(
    smartComponentName: T,
    values: Partial<TypeSmartComponentAdditionalOptionsStore[T]>
  ) => {
    this.additionalOptions[smartComponentName] = values;
  };
}
