import type { ReactNode } from 'react';
import {
  ItemCardAction,
  ItemCardContent,
  ItemCardHeader,
  ItemCardWrapper,
} from './footer-smart-component.styles';

type ItemCardType = {
  name: string;
  value: ReactNode;
  action?: () => void;
  labelTest?: string;
};

export const ItemCard = ({ name, value, action, labelTest }: ItemCardType) => (
  <ItemCardWrapper data-test={labelTest}>
    <ItemCardHeader>{name}</ItemCardHeader>
    <ItemCardContent>
      {action ? (
        <ItemCardAction onClick={action} data-test="Card-action">
          {value}
        </ItemCardAction>
      ) : (
        value
      )}
    </ItemCardContent>
  </ItemCardWrapper>
);
