/* eslint-disable indent */
import type { WhoIsPolicyholderNames } from '@smart-components/who-is-policyholder/types';
import { i18nDefaultValues } from '../i18n';
import i18n from '@app/i18n/config';

export const getPolicyholderLabel = (
  name: Exclude<
    WhoIsPolicyholderNames,
    'isValid' | 'hasAuthorized' | 'client' | 'forMe'
  >
) =>
  i18n.t(`SMART:Policyholder.label.${name}`, {
    defaultValue: i18nDefaultValues.Policyholder.labels[name],
  });
