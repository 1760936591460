import { InsuranceRisks } from '@entities/import-smart-components/insurance-risks';
import { FallbackSkeleton } from '@shared/components';
import { observer } from 'mobx-react-lite';
import { type FC, Suspense, forwardRef, type Ref } from 'react';
import type {
  InsuranceRisksValues,
  SmartComponentAdapterProps,
} from '@smart-components/index';

export const InsuranceRisksAdapter: FC<
  SmartComponentAdapterProps<InsuranceRisksValues>
> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      if (!value?.insuranceRisks?.length)
        return <FallbackSkeleton height={274} />;
      return (
        <Suspense fallback={<FallbackSkeleton height={274} />}>
          <InsuranceRisks
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            fieldState={fieldState}
          />
        </Suspense>
      );
    }
  )
);

InsuranceRisksAdapter.displayName = 'InsuranceRisksAdapter';
