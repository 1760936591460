import { Endpoints } from '@shared/constants';
import type { Car } from '@shared/types';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';

export const useHandleCarNumber = (code?: string, carNumber?: string) => {
  return useQuery(
    ['get-car', code, carNumber],
    fetchData<Car>({
      url: `${Endpoints.GET_CAR}?regNum=${carNumber}&productVersion=${code}`,
      method: 'GET',
    }),
    { enabled: false }
  );
};
