/* eslint-disable indent */
import { useHandleErrorRetry, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import type { FC } from 'react';
import {
  useHandleQueries,
  useHandleMobileParams,
  useHandleTerritory,
  useHandleSportList,
  useInsuranceLimitStart,
  useGetAgeRatio,
  useHandleSessionRecovery,
  useDeveloperMode,
} from './hooks';
import { useHandleProduct } from './queries';

export const InitBox: FC = observer(() => {
  const {
    MainStore: {
      applicationStore: { setLoading },
      initProductStore: { setInitState, initState },
      errorStore: { setErrorRetry },
    },
  } = useStores();

  useDeveloperMode();

  useHandleQueries();
  useHandleMobileParams();
  const { isLoadingCountry, resCountry, refetchCountry } = useHandleTerritory();
  //Запрос на инициализацию продукта
  useHandleProduct();
  const { isLoadingSportList, resSportList, refetchSportList } =
    useHandleSportList();
  const { resAgeRatio, isLoadingAgeRation, refetchAgeRatio } = useGetAgeRatio();
  const {
    resInsuranceLimitStart,
    isLoadingInsuranceLimitStart,
    refetchInsuranceLimit,
  } = useInsuranceLimitStart();

  //TODO: Добавить лоадер при восстановлении ссесси, в рамках задачи [WEB] Добавление лоадеров на шаги и компоненты (https://pulse-insure.atlassian.net/browse/PROD-11571)
  useHandleSessionRecovery();

  useEffect(() => {
    if (
      !isLoadingCountry &&
      !isLoadingSportList &&
      resCountry &&
      resSportList
    ) {
      setInitState({
        ...initState,
        ...resCountry,
        ...resSportList,
      });
      setLoading(false);
      setErrorRetry(false);
    }
  }, [isLoadingSportList, resSportList, isLoadingCountry, resCountry]);

  useEffect(() => {
    if (!isLoadingInsuranceLimitStart && resInsuranceLimitStart) {
      setErrorRetry(false);
    }
  }, [isLoadingInsuranceLimitStart, resInsuranceLimitStart]);

  useEffect(() => {
    if (!isLoadingAgeRation && resAgeRatio) {
      setErrorRetry(false);
    }
  }, [isLoadingAgeRation, resAgeRatio]);

  const selectRefetch = (index: string) => {
    switch (index) {
      case 'INSURANSE_LIMIT_START':
        return refetchInsuranceLimit();
      // case 'GET_INSURANCE_PRODUCT':
      //   return refetchProduct();
      case 'GET_AGE_RATION':
        return refetchAgeRatio();
      case 'TERRITORY':
        return refetchCountry();
      case 'GET_SPORT_ACTIVITY':
        return refetchSportList();
    }
  };

  useHandleErrorRetry(selectRefetch);

  return null;
});
