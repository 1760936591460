import { DocumentsList } from '@entities/import-smart-components/documents-list';
import { FallbackSkeleton } from '@shared/components';
import { useGetRisksOptionsLength, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { type FC, Suspense, useMemo, forwardRef, type Ref } from 'react';
import type {
  SmartComponentAdapterProps,
  DocumentsListOptions,
  DocumentsListValues,
  InsurantUserInfo,
} from '@smart-components/index';

export const DocumentsListAdapter: FC<
  SmartComponentAdapterProps<DocumentsListValues>
> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          initProductStore: { initState },
          applicationStore: { setActiveStepByName, paymenStep },
          productStore: {
            currency,
            formState,
            fullRisksOptionsLength,
            agentLogin,
          },
          authStore: { disableForm },
        },
      } = useStores();

      const risks = formState?.WantImprovePolicy?.fullOptionsRisks;
      const coverageSum = formState?.WhoAndHow?.coverageSum;
      const initStateRisks = initState?.risks;
      const fullOptionsRisks = formState.WantImprovePolicy?.fullOptionsRisks;
      const whoIssuesPolicy = formState?.WhoIssuesPolicy;
      const isAcceptCheckboxDisabled = disableForm || paymenStep;

      const { formattedOptionsString: selectedRisksLabel } =
        useGetRisksOptionsLength(fullRisksOptionsLength, fullOptionsRisks);

      const insurantInfo: InsurantUserInfo = useMemo(
        () => ({
          name: `${whoIssuesPolicy?.surname} ${whoIssuesPolicy?.name} ${whoIssuesPolicy?.secondName}`,
          phone: whoIssuesPolicy?.phone || '',
          email: whoIssuesPolicy?.email || '',
        }),
        [whoIssuesPolicy]
      );

      const options: DocumentsListOptions = useMemo(
        () => ({
          risks,
          currency,
          coverageSum,
          initStateRisks,
          setActiveStepByName,
          paymenStep,
          selectedRisksLabel,
          agentLogin,
          insurantInfo,
        }),
        [
          risks,
          currency,
          coverageSum,
          initStateRisks,
          paymenStep,
          setActiveStepByName,
          selectedRisksLabel,
          agentLogin,
          insurantInfo,
        ]
      );

      return (
        <Suspense fallback={<FallbackSkeleton height={280} />}>
          <DocumentsList
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={isAcceptCheckboxDisabled}
            fieldState={fieldState}
          />
        </Suspense>
      );
    }
  )
);

DocumentsListAdapter.displayName = 'DocumentsListAdapter';
