import { useCallback, useEffect, useState } from 'react';
import FastAuth, {
  type FastAuthValues,
} from '@pulse-smart-components-kit/fast-auth';
import type {
  FastAuthBannerOptions,
  FastAuthBannerProps,
} from './fast-auth-banner.types';
import { useTranslation } from 'react-i18next';

import { i18nDefaultValues } from '../../i18n';

export const FastAuthBanner = ({
  isOtpError,
  handleAuth,
  handleOtp,
  setRefetchAuthentication,
  isUserNotDefine,
  setIsUserNotDefine,
  setIsFastAuthOpen,
}: FastAuthBannerProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<FastAuthValues>();
  const [options, setOptions] = useState<FastAuthBannerOptions>({
    isSuccessPhoneAuth: false,
    isLoading: false,
  });

  const handleChange = useCallback(
    (data: FastAuthValues) => {
      if (data?.isValid) {
        if (data?.phone && !options.isSuccessPhoneAuth) {
          handleAuth(data.phone);
          setOptions((prev) => ({
            ...prev,
            isSuccessPhoneAuth: true,
          }));
        }
        if (data?.code && options.isSuccessPhoneAuth) {
          handleOtp(data.code, true);
        }
        setOptions((prev) => ({ ...prev, isLoading: false }));
      }
      setValue(data);
    },
    [value, setValue, options, setOptions]
  );

  useEffect(() => {
    if (value?.code && value.isValid && options.isSuccessPhoneAuth) {
      handleOtp(value.code, true);
    }
  }, [value?.code, value?.isValid, options?.isSuccessPhoneAuth]);

  const handleChangePhone = useCallback(() => {
    setIsUserNotDefine(false);
    setOptions((prev) => ({
      ...prev,
      isSuccessPhoneAuth: false,
    }));
  }, [setOptions]);

  const otpErrorMessage = {
    code: {
      message: t('SMART:WhoIsPolicyholder.errors.incorrectCodeFromSms', {
        defaultValue:
          i18nDefaultValues.WhoIsPolicyholder.errors.incorrectCodeFromSms,
      }),
    },
  };

  const userIsNotDefineMessage = {
    phone: {
      message: t('SMART:WhoIsPolicyholder.errors.userIsNotDefine', {
        defaultValue:
          i18nDefaultValues.WhoIsPolicyholder.errors.userIsNotDefine,
      }),
    },
  };

  const errorMessages = {
    error: {
      ...(isOtpError ? otpErrorMessage : {}),
      ...(isUserNotDefine ? userIsNotDefineMessage : {}),
    },
  };

  const handleChangeGetNewCode = () => {
    setRefetchAuthentication(true);
  };

  const handleToggleModal = useCallback(
    (isOpen: boolean) => {
      setIsFastAuthOpen(isOpen);

      if (!isOpen) {
        setIsUserNotDefine(false);
      }
    },
    [setIsFastAuthOpen]
  );

  return (
    <FastAuth
      value={value}
      onChange={handleChange}
      options={{
        ...options,
        onChangePhone: handleChangePhone,
        onGetNewCode: handleChangeGetNewCode,
        onToggleModal: handleToggleModal,
      }}
      formContext={{ isSuccessPhoneAuth: options.isSuccessPhoneAuth }}
      fieldState={errorMessages}
      isSubmitting={false}
    />
  );
};
