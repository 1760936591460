import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { WantImprovePolicy } from '@entities/import-smart-components/want-improve-policy';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import type { AnalyticEventsMap } from '@shared/types';
import { observer } from 'mobx-react-lite';
import { type FC, Suspense, useMemo, forwardRef, type Ref } from 'react';
import type {
  SmartComponentAdapterProps,
  WantImprovePolicyOptions,
  WantImprovePolicyValues,
} from '@smart-components/index';
import { WantImprovePolicyAnalyticEvent } from '@smart-components/want-improve-policy';

const analyticEventsMap: AnalyticEventsMap<WantImprovePolicyAnalyticEvent> = {
  [WantImprovePolicyAnalyticEvent.ON_MODAL_OPEN]: {
    name: analyticEvents.travelViewOptionsOptions,
  },
  [WantImprovePolicyAnalyticEvent.ON_CARD_CLICK]: {
    name: analyticEvents.travelOptionAdded,
  },
};

export const WantImprovePolicyAdapter: FC<
  SmartComponentAdapterProps<WantImprovePolicyValues>
> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          initProductStore: { initState },
          productStore: { currency, formState },
          authStore: { disableForm },
        },
      } = useStores();

      const coverageSum = formState?.WhoAndHow?.coverageSum;
      const initStateRisks = initState?.risks;

      const options: WantImprovePolicyOptions = useMemo(
        () => ({
          currency,
          coverageSum,
          initStateRisks,
        }),
        [currency, coverageSum, initStateRisks]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<WantImprovePolicyAnalyticEvent>(
          analyticEventsMap
        );

      return (
        <Suspense fallback={<FallbackSkeleton height={460} />}>
          <WantImprovePolicy
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldState}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

WantImprovePolicyAdapter.displayName = 'WantImprovePolicyAdapter';
