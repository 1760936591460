/* eslint-disable indent */
import { Suspense, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { CurrencyLabel } from '@shared/constants';
import { useStores } from '@shared/hooks';
import { formattingNumber } from '@shared/utils';

import type { FC } from 'react';
import { Submit } from '@entities/import-smart-components/submit';
import {
  FormBuilderEventBus,
  FormBuilderAction,
} from '@features/form-builder/utils';
import {
  getFormInsuranceContactsSchema,
  getPhoneValidationSchema,
} from '@shared/scheme';

export const SubmitAdapter: FC = observer(() => {
  const { t } = useTranslation();
  const {
    MainStore: {
      applicationStore: {
        currentStep,
        disabledSubmit,
        flowConfig: { otpStep, maxSteps },
        isCarNumberSmartComponentShowing,
      },
      authStore: {
        setExternalAuthPhone,
        hasAuthorized,
        hasPhone,
        handleOtp,
        externalAuthPhone,
        setDisableForm,
        otpIsLoading,
        authIsLoading,
        disableForm,
        otpErrorCode,
        setOtpErrorCode,
        authError,
        useOtp,
        setRefetchAuthentication,
      },
      initProductStore: { initOrder },
      productStore: {
        setPulseClient,
        pulseClient,
        formState,
        formString,
        price,
        hasPricesCalcErr,
        orderData,
        setPrice,
        agentLogin,
        clearPromoCode,
      },
      applicationStore: { activeStepHasName },
    },
  } = useStores();

  const buttonText = useMemo(() => {
    const paymentVal = formattingNumber(
      Number(orderData?.premiumAndDelta || '')
    );
    const currency = CurrencyLabel.RUB;

    switch (currentStep) {
      case maxSteps:
        return t('COMMON:labels.getPayment', {
          paymentVal,
          currency,
        });
      case otpStep:
        return hasAuthorized ? undefined : t('COMMON:labels.submitGetCode');
      default:
        return undefined;
    }
  }, [currentStep, hasAuthorized, orderData?.premiumAndDelta]);

  const handleSubmit = useCallback(() => {
    FormBuilderEventBus.publish<null>(FormBuilderAction.SUBMIT_FORM, null);
  }, []);

  const authUserHandler = useCallback((phone: string) => {
    FormBuilderEventBus.publish(FormBuilderAction.AUTH_USER, {
      phone,
    });
  }, []);

  useEffect(() => {
    if (formState?.WhoIsPolicyholder) {
      setPulseClient(formState?.WhoIsPolicyholder?.client || false);
    }

    if (
      !formState?.WhoIsPolicyholder?.client &&
      getFormInsuranceContactsSchema(agentLogin).isValidSync(
        formState?.WhoIsPolicyholder
      )
    ) {
      setExternalAuthPhone(formState?.WhoIsPolicyholder?.phone);
    } else if (
      formState?.WhoIsPolicyholder?.client &&
      getPhoneValidationSchema(agentLogin).isValidSync(
        formState?.WhoIsPolicyholder?.phone
      )
    ) {
      setExternalAuthPhone(String(formState?.WhoIsPolicyholder?.phone));
    } else {
      setExternalAuthPhone(undefined);
    }
  }, [formString]);

  const shouldHideSubmit = useMemo(() => {
    const isAgentFlow = !!agentLogin;
    const isPaymentStep = currentStep === maxSteps;

    return isPaymentStep && (!!initOrder || isAgentFlow);
  }, [currentStep, initOrder, agentLogin]);

  return (
    <Suspense>
      <Submit
        buttonText={buttonText}
        onSubmit={handleSubmit}
        hasAuthorized={hasAuthorized}
        useOtp={useOtp && currentStep === otpStep}
        hasPhone={hasPhone}
        handleOtp={handleOtp}
        handleAuth={authUserHandler}
        externalPhone={externalAuthPhone}
        setDisableForm={setDisableForm}
        otpIsLoading={otpIsLoading}
        authIsLoading={authIsLoading}
        disabled={disableForm || disabledSubmit}
        hideSubmit={shouldHideSubmit}
        hasPricesCalcErr={hasPricesCalcErr}
        otpErrorCode={otpErrorCode}
        setOtpErrorCode={setOtpErrorCode}
        authError={authError}
        currentStep={currentStep}
        refetchAuthentication={setRefetchAuthentication}
        pulseClient={pulseClient}
        onSetPrice={setPrice}
        onClearPromoCode={clearPromoCode}
        price={price}
        isShowPromoCode={false}
        isRender={!isCarNumberSmartComponentShowing}
        activeStepHasName={activeStepHasName}
      />
    </Suspense>
  );
});

SubmitAdapter.displayName = 'SubmitAdapter';
