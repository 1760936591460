import { useTranslation } from 'react-i18next';

import {
  Container,
  CountIcon,
  DeleteDriver,
  NumberDriver,
  TitleDriver,
} from './title.styles';
import { i18nDefaultValues } from '../../i18n';

import type { FC } from 'react';
import type { TitleProps } from './types';

export const Title: FC<TitleProps> = ({
  isMe,
  serialNumber,
  fieldsLength,
  remove,
}) => {
  const { t } = useTranslation();
  const driverTitle = t('SMART:WhoIsDriver.headers.driver', {
    defaultValue: i18nDefaultValues.WhoIsDriver.headers.driver,
  });
  const youDriverTitle = t('SMART:WhoIsDriver.headers.youDriver', {
    defaultValue: i18nDefaultValues.WhoIsDriver.headers.youDriver,
  });

  const isDriverYou = isMe && serialNumber === 1;

  return (
    <Container>
      <NumberDriver>
        <CountIcon>{serialNumber}</CountIcon>
        <TitleDriver>{isDriverYou ? youDriverTitle : driverTitle}</TitleDriver>
      </NumberDriver>
      {fieldsLength > 1 && (
        <DeleteDriver variant="text" onClick={remove}>
          {t('SMART:WhoIsDriver.labels.delete', {
            defaultValue: i18nDefaultValues.WhoIsDriver.labels.delete,
          })}
        </DeleteDriver>
      )}
    </Container>
  );
};
