/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { ObjectSchema } from 'yup';

/*
 * Этот хук помогает вызывать setValue при изменении значения во внешней форме.
 * Сравнение идёт по поверхности с первой глубиной вложенности объекта.
 * Для сложных случаев сравнения есть возможность расширить текущий компаратор или переопределить его.
 */
export const useUpdateLocalForm = <T>(
  getValues: () => T,
  newValue: T | undefined,
  setValue: (k: keyof T, v: T[keyof T]) => void,
  scheme: ObjectSchema<any>,
  comparator?: (a: T[keyof T], b: T[keyof T], field: keyof T) => boolean
) => {
  const [fields, setFields] = useState<(keyof T)[]>([]);

  useEffect(() => {
    setFields(Object.keys(scheme.describe().fields) as (keyof T)[]);
  }, [scheme]);

  useEffect(() => {
    if (newValue) {
      fields.forEach((field) => {
        if (
          !isEqual<T>(getValues()[field], newValue[field], field, comparator)
        ) {
          setValue(field, newValue[field]);
        }
      });
    }
  }, [newValue, fields, comparator]);
};

export const baseComparator = (a: any, b: any) => {
  if (a?.constructor == Date && b?.constructor == Date) {
    return a.getTime() == b.getTime();
  }

  return a == b;
};

// TODO: экспортировать изменения в платформу
const isEqual = <T>(
  a: T[keyof T],
  b: T[keyof T],
  field: keyof T,
  comparator?: (a: T[keyof T], b: T[keyof T], field: keyof T) => boolean
): boolean => {
  if (comparator) return comparator(a, b, field);

  return baseComparator(a, b);
};
