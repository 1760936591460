import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { forwardRef, Ref, Suspense, useMemo } from 'react';
import { SmartComponentAdapterProps } from '@smart-components/shared/types';
import { Drivers } from '@smart-components/drivers/index';
import { DriversValues } from '@smart-components/drivers/types';

export const DriversAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
      }: SmartComponentAdapterProps<DriversValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          initProductStore: {
            initState: { extraParams },
          },
          applicationStore: { isCarNumberSmartComponentShowing },
        },
      } = useStores();

      const options = useMemo(() => {
        const minAgeOfDriver = Number(
          extraParams?.find(
            (extraParam) => extraParam.code === 'minAgeOfDriver'
          )?.value
        );
        const minExperienceOfDriver = Number(
          extraParams?.find(
            (extraParam) => extraParam.code === 'minExperienceOfDriver'
          )?.value
        );

        return {
          minAgeOfDriver,
          minExperienceOfDriver,
        };
      }, [extraParams]);

      if (isCarNumberSmartComponentShowing) return;

      return (
        <Suspense fallback={<FallbackSkeleton height={274} />}>
          <Drivers
            ref={forwardRef}
            isSubmitting={isSubmitting}
            value={value}
            onChange={onChange}
            options={options}
          />
        </Suspense>
      );
    }
  )
);

DriversAdapter.displayName = 'DriversAdapter';
