import type {
  AgeRatio,
  Country,
  ProductPrice,
  Traveler,
  StoreTraveler,
  Car,
  Driver,
} from '@shared/types';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import {
  getAgeFormStoreTraveler,
  getAgeRange,
  getCurrency,
  getTravelerFormStoreTraveler,
  updateTravelersAges,
  updateTravelersData,
} from './utils';
import { Main } from '@app/store';
import { FieldValues } from 'react-hook-form';
import type {
  AgeSelectItem,
  CarNumberValues,
  CarValues,
  DriversValues,
  InsuranceParametersValues,
  InsuranceRisksValues,
  CarOwnerValues,
  WhoIsDriverValues,
  PolicyholderDataValues,
  VehicleDocumentValues,
  PolicyholderFormValues,
} from '@smart-components/index';
import type { SubmitResponse } from '@entities/adapters/submit-adapter/types';

const ageRatios: AgeRatio[] = [
  {
    ageMin: 1,
    ageMax: 90,
    ageCategory: 'Взрослый',
  },
];

type FormStateType = {
  CarNumber?: CarNumberValues;
  Car?: CarValues;
  Drivers?: DriversValues;
  WhoAndHow?: FieldValues;
  WhoIssuesPolicy?: FieldValues;
  WhoIsPolicyholder?: FieldValues;
  WantImprovePolicy?: FieldValues;
  WhereAndHowLong?: FieldValues;
  WhoAndHowPaperwork?: FieldValues;
  DocumentsList?: FieldValues;
  InsuranceParameters?: InsuranceParametersValues;
  PolicyholderData?: PolicyholderDataValues;
  PolicyholderForm?: PolicyholderFormValues;
  InsuranceRisks?: InsuranceRisksValues;
  CarOwner?: CarOwnerValues;
  WhoIsDriver?: WhoIsDriverValues;
  VehicleDocument?: VehicleDocumentValues;
};

export class ProductStore {
  private mainStore: Main;
  pulseClient?: boolean; // Связь с чекбоксом “Я клиент СК «Пульс»” на авторизации
  ageRatios: AgeRatio[] = ageRatios; // Для валидации, когда мы выбираем возраст путешественника
  fullRisksOptionsLength: number = 0; // Длина рисков и опций приходящих с бекенда, фильтруется на фронте
  formState: FormStateType = {}; // Форма со смарт компонентами и их базовыми свойствами
  car: Car = { requestId: '' }; //
  formString: string = ''; // Форма смарт компонентов в виде строки, используется в bff
  price: ProductPrice = { premiumAndDelta: '20000', promoCode: '' }; // Цена в футере
  orderData?: SubmitResponse = undefined; // Данные полученные с post запроса submit-order
  hasPricesCalcErr: boolean = false; // Ошибка в цене, отрисовка в cостояние error, если сумма превышает досигаемой.
  isPolicyBanned: boolean = false; // Признак ошибки о невозможности оформления полиса.
  agentLogin?: string;
  travelers: StoreTraveler[] = []; // Единый список путешественников для синхронизации между смарт компонентами.
  drivers: Driver[] = []; // Единый список водителей для синхронизации между смарт компонентами.

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this, {
      car: observable,
      pulseClient: observable,
      setPulseClient: action,
      ageRatios: observable,
      setAgeRatios: action,
      fullRisksOptionsLength: observable,
      setFullRisksOptionsLength: action,
      formState: observable,
      setFormState: action,
      setOrderData: action,
      formString: observable,
      price: observable,
      setPrice: action,
      setHasPricesCalcErr: action,
      agentLogin: observable,
      isPolicyBanned: observable,
      setAgentLogin: action,
      clearPromoCode: action,
      setIsPolicyBanned: action,
      travelers: observable,
      drivers: observable,
      setTravelersAges: action,
      setTravelersData: action,
    });
  }

  get countries(): Country[] | undefined {
    return this.formState?.WhereAndHowLong?.countries;
  }

  get insuranceSumm(): string {
    return this.formState.WhoAndHow?.coverageSum;
  }

  get travelDates(): { start: Date | undefined; end: Date | undefined } {
    return this.formState.WhereAndHowLong?.travelDates;
  }

  get ageRange() {
    return getAgeRange(this.ageRatios);
  }

  get currency() {
    return getCurrency(this);
  }

  setPrice = (e: Partial<ProductPrice>) => {
    this.price = { ...this.price, ...e };
  };

  clearPromoCode = () => {
    this.price = {
      ...this.price,
      isSuccessfulPromo: undefined,
      premiumAndDeltaPromo: undefined,
    };
  };

  setCar = (e: Car) => {
    this.car = e;
  };

  setHasPricesCalcErr = (e: boolean) => {
    this.hasPricesCalcErr = e;
  };

  setAgeRatios = (e: AgeRatio[]) => {
    this.ageRatios = e;
  };

  setOrderData = (e: SubmitResponse) => {
    this.orderData = e;
  };

  setPulseClient = (e: boolean) => {
    this.pulseClient = e;
  };

  setFormState = (e: Partial<FormStateType>) => {
    runInAction(() => {
      this.formState = {
        ...e,
      };
      this.formString = JSON.stringify(e);
    });
  };

  setFullRisksOptionsLength = (e: number) => {
    this.fullRisksOptionsLength = e;
  };

  setIsPolicyBanned = (e: boolean) => {
    this.isPolicyBanned = e;
    runInAction(() => {
      this.mainStore.applicationStore.setIsHeaderShowing(!e);
      this.mainStore.applicationStore.setIsFooterShowing(!e);
      this.mainStore.applicationStore.setIsSubmitShowing(!e);
      this.mainStore.applicationStore.setIsSmartComponentsShowing(!e);
    });
  };

  setAgentLogin = (e: string) => {
    this.agentLogin = e;
  };

  setTravelersAges = (
    e: AgeSelectItem[],
    onClearMyselfAge: VoidFn
  ): Traveler[] => {
    this.travelers = updateTravelersAges(this.travelers, e, onClearMyselfAge);

    return this.travelers.map(getTravelerFormStoreTraveler);
  };

  setTravelersData = (e: Traveler[]): AgeSelectItem[] => {
    if (!this.mainStore.authStore.hasAuthorized)
      return this.travelers.map(getAgeFormStoreTraveler);

    this.travelers = updateTravelersData(e);

    return this.travelers.map(getAgeFormStoreTraveler);
  };
}
