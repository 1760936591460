import { type Ref, forwardRef, memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';

import { i18nDefaultValues } from './i18n';
import type { CarOwnerValues, CarOwnerOptions } from './types';
import type { SmartComponentProps } from '@smart-components/index';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { Row, Form, Container } from './car-owner.styles';
import { Policyholder } from '@shared/components';

import { schema } from './schema';

//TODO: доработать, когда будет готова веб-аналитика

export const CarOwner = memo(
  forwardRef(
    (
      {
        value,
        options: { isPolicyholderTheOwner, onIsPolicyholderTheOwnerChange },
        isSubmitting,
        onChange,
      }: SmartComponentProps<CarOwnerValues, CarOwnerOptions>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();

      const {
        control,
        clearErrors,
        setValue,
        trigger,
        watch,
        formState: { isValid },
      } = useForm<CarOwnerValues>({
        values: value,
        mode: 'onSubmit',
        resolver: yupResolver(schema),
        context: {},
      });

      const handleCheck = useCallback(() => {
        onIsPolicyholderTheOwnerChange(!isPolicyholderTheOwner);
      }, [isPolicyholderTheOwner, onIsPolicyholderTheOwnerChange]);

      const getDisableState = useCallback(() => {
        return false;
      }, []);

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        const subscription = watch((values) => {
          onChange(values);
        });

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        setValue('isValid', isValid);
      }, [isValid]);

      return (
        <WidgetContainer ref={forwardRef} data-testid="car-owner">
          <Form>
            <HeaderWithSubText
              title={t('SMART:CarOwner.title', {
                defaultValue: i18nDefaultValues.CarOwner.title,
              })}
            />
            <Container>
              <Row>
                <Checkbox
                  onChange={handleCheck}
                  checked={isPolicyholderTheOwner}
                  label={t('SMART:CarOwner.labels.client', {
                    defaultValue: i18nDefaultValues.CarOwner.labels.client,
                  })}
                  data-testid="is-policyholder-the-owner"
                />
              </Row>
              {!isPolicyholderTheOwner && (
                <Policyholder
                  control={control}
                  clearErrors={clearErrors}
                  getDisableState={getDisableState}
                  setValue={setValue}
                />
              )}
            </Container>
          </Form>
        </WidgetContainer>
      );
    }
  )
);
