import { Alert } from '@pulse-web-ui/alert';
import { getAlerts } from '@smart-components/where-and-how-long/utils/get-alerts';

import type { FC } from 'react';
import type { Props } from './warning.types';

export const Warning: FC<Props> = ({ warnings, options }) => {
  const insurancePeriodOneTimeMax = options?.insurancePeriodOneTimeMax || '';
  return warnings.map((warning) => (
    <Alert
      key={warning}
      type="warning"
      title={
        getAlerts({ insurancePeriodOneTimeMax }).find((e) => e.name === warning)
          ?.title
      }
      description={
        getAlerts({ insurancePeriodOneTimeMax }).find((e) => e.name === warning)
          ?.description
      }
    />
  ));
};
