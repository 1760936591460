import type {
  CarValues,
  InsuranceParametersValues,
  VehicleDocumentValues,
} from '@smart-components/index';
import { format } from 'date-fns';
import type { InsuranceObject } from '../types';
import { getFranchise } from '@shared/utils';
import { DATE_FORMAT, Document } from '@shared/constants';
import { OBJECT_TYPE } from '../constants';
import { getDataDocument } from './get-data-document';

export interface Params {
  car?: CarValues;
  insuranceParameters?: InsuranceParametersValues;
  vehicleDocument?: VehicleDocumentValues;
}

export const getInsuranceObject = ({
  car,
  insuranceParameters,
  vehicleDocument,
}: Params): InsuranceObject => {
  const [series, number] = getDataDocument(
    vehicleDocument?.documentNumber ?? '',
    (vehicleDocument?.document ?? '') as Document
  );
  const document = {
    documentType: vehicleDocument?.document ?? '',
    vehicleDocSeries: series.replace(' ', ''),
    vehicleDocNumber: number,
    vehicleDocIssueDate: format(
      vehicleDocument?.documentDate ?? new Date(),
      DATE_FORMAT
    ),
  };
  return {
    objectType: OBJECT_TYPE,
    licensePlate: car?.carNumber.replaceAll(' ', '') ?? '',
    vin: car?.vin ?? '',
    make: car?.car?.brand?.label ?? '',
    makeId: car?.car?.brand?.value ?? '',
    model: car?.car?.model?.label ?? '',
    modelId: car?.car?.model?.value ?? '',
    year: Number(car?.car?.manufactureYear?.value ?? 0),
    enginePower: Number(car?.car?.power?.value ?? 0),
    mileage: car?.mileage ?? 0,
    price: Number(car?.marketPrice) || 0,
    region: insuranceParameters?.region ?? '',
    isCredit: Boolean(car?.carOnCredit),
    stoType: insuranceParameters?.repair ?? '',
    vehicleDocument: document,
    franchise: getFranchise(Number(insuranceParameters?.franchise ?? 0)),
  };
};
