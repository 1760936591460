import { DigitsAndCyrillicRegEx, DigitsRegEx } from '@shared/constants';

export const DEFAULT_DRIVER = {
  name: '',
  surname: '',
  middlename: '',
  birthday: null,
  license: '',
  yearOfStart: '',
  licenseDate: null,
};

export const LICENSE_MASK = [
  DigitsRegEx,
  DigitsRegEx,
  ' ',
  DigitsAndCyrillicRegEx,
  DigitsAndCyrillicRegEx,
  ' ',
  DigitsRegEx,
  DigitsRegEx,
  DigitsRegEx,
  DigitsRegEx,
  DigitsRegEx,
  DigitsRegEx,
];

export const YEAR_OF_START_MASK = [
  DigitsRegEx,
  DigitsRegEx,
  DigitsRegEx,
  DigitsRegEx,
];

export const LICENSE_PLACEHOLDER = '00 XX 000000';
export const YEAR_OF_START_PLACEHOLDER = '0000';

export const ALL_ZEROS = '000000';
