/* eslint-disable indent */
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  type Ref,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';

import { i18nDefaultValues } from './i18n';
import type {
  WhoIsPolicyholderValues,
  WhoIsPolicyholderOptions,
  WhoIsPolicyholderDisableState,
} from './types';
import type { SmartComponentProps } from '@smart-components/shared/types';
import { yupResolver } from '@hookform/resolvers/yup';

import { WidgetContainer } from '@pulse-web-ui/containers';
import { Form, Container } from './who-is-policyholder.styles';
import { FastAuthBanner } from './components';

import { PhoneAndEmail, Policyholder } from '@shared/components';
import { getFormInsuranceContactsSchema } from '@shared/scheme';
import { useUpdateLocalForm, baseComparator } from '@shared/hooks';
import isEqual from 'lodash.isequal';

//TODO: доработать, когда будет готова веб-аналитика

export const WhoIsPolicyholder = memo(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        disabled,
        options: {
          agentLogin,
          deviceType,
          phoneFromMobileApp,
          isOtpError,
          handleAuth,
          handleOtp,
          setRefetchAuthentication,
          isUserNotDefine,
          setIsUserNotDefine,
          setIsFastAuthOpen,
        },
      }: SmartComponentProps<WhoIsPolicyholderValues, WhoIsPolicyholderOptions>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();

      const formInsuranceContactsSchema = useMemo(
        () => getFormInsuranceContactsSchema(agentLogin),
        [agentLogin]
      );

      const {
        trigger,
        clearErrors,
        control,
        watch,
        getValues,
        setValue,
        formState: { isValid },
        reset,
      } = useForm<WhoIsPolicyholderValues>({
        values: value,
        mode: 'onSubmit',
        resetOptions: {
          keepDirtyValues: true,
          keepErrors: true,
        },
        resolver: yupResolver(formInsuranceContactsSchema),
        context: {},
      });

      const hasAuthorized = getValues('hasAuthorized');

      const comparator = useCallback(
        (
          a: WhoIsPolicyholderValues[keyof WhoIsPolicyholderValues],
          b: WhoIsPolicyholderValues[keyof WhoIsPolicyholderValues],
          field: keyof WhoIsPolicyholderValues
        ) => {
          if (field === 'address') {
            return isEqual(a, b);
          }

          return baseComparator(a, b);
        },
        []
      );

      useUpdateLocalForm<WhoIsPolicyholderValues>(
        getValues,
        value,
        setValue,
        formInsuranceContactsSchema,
        comparator
      );

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        if ((value?.hasAuthorized && !hasAuthorized) || !value) {
          reset(value);
          trigger();
        }
      }, [value]);

      useEffect(() => {
        const subscription = watch((values) => {
          onChange(values as WhoIsPolicyholderValues);
        });

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        setValue('isValid', hasAuthorized || isValid);
      }, [isValid, hasAuthorized]);

      const getDisableState = useCallback(
        (name: keyof WhoIsPolicyholderDisableState) => {
          if (hasAuthorized || disabled) {
            return true;
          }
          switch (name) {
            case 'phone':
              return Boolean(deviceType) && Boolean(phoneFromMobileApp);
          }

          return false;
        },
        [hasAuthorized, disabled, deviceType, phoneFromMobileApp]
      );

      if (hasAuthorized) return null;

      return (
        <WidgetContainer ref={forwardRef} data-testid="who-is-policyholder">
          <Form>
            <HeaderWithSubText
              title={t('SMART:WhoIsPolicyholder.title', {
                defaultValue: i18nDefaultValues.WhoIsPolicyholder.title,
              })}
            />
            <Container>
              <FastAuthBanner
                isOtpError={isOtpError}
                handleAuth={handleAuth}
                handleOtp={handleOtp}
                setRefetchAuthentication={setRefetchAuthentication}
                isUserNotDefine={isUserNotDefine}
                setIsUserNotDefine={setIsUserNotDefine}
                setIsFastAuthOpen={setIsFastAuthOpen}
              />
              <Policyholder
                control={control}
                clearErrors={clearErrors}
                getDisableState={getDisableState}
                setValue={setValue}
              />
              <PhoneAndEmail<
                WhoIsPolicyholderValues,
                WhoIsPolicyholderDisableState
              >
                control={control}
                clearErrors={clearErrors}
                getDisableState={getDisableState}
                setValue={setValue}
              />
            </Container>
          </Form>
        </WidgetContainer>
      );
    }
  )
);
